import React from "react";
import styles from "./Button.module.scss";

const Button = ({ children, onClick, color, block, style, size, disabled }) => {
  function handleClick() {
    if (typeof onClick === "function") {
      onClick();
    }
  }

  let className = styles.green;
  switch (color) {
    case "red":
      className = styles.red;
      break;
    case "blue":
      className = styles.blue;
      break;
    case "gray":
      className = styles.gray;
      break;
  }

  return (
    <button
      disabled={disabled}
      style={style || {}}
      onClick={handleClick}
      className={`${styles.button} ${styles.md} ${className} ${
        block ? styles.block : ""
      }`}
    >
      {children}
    </button>
  );
};

export default Button;
