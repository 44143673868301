import { store } from "../../../config/store";
import { changePlayerDirection, changePosition } from "../reducer";
import maps from "../../../data/maps";
import changeMap from "../../Map/actions/changeMap";
import { errorMessage } from "../../Messages/actions/message-manager";

export default function movePlayer(direction) {
  store.dispatch(changePlayerDirection(direction));

  const oldPos = store.getState().player.position;

  const newPos = getNewPosition(oldPos, direction);

  if (!canMove(newPos)) {
    return false;
  }

  if (checkForMonster(newPos)) {
    return false;
  }

  if (checkForCharacter(newPos)) {
    return false;
  }

  if (!observeBoundaries(newPos)) {
    // return false;
  }

  store.dispatch(changePosition(newPos));

  return true;
}

export function getNewPosition(oldPos, direction) {
  switch (direction) {
    case "left":
      return [oldPos[0] - 1, oldPos[1]];
    case "right":
      return [oldPos[0] + 1, oldPos[1]];
    case "up":
      return [oldPos[0], oldPos[1] - 1];
    case "down":
      return [oldPos[0], oldPos[1] + 1];
    default:
  }
}

export function observeBoundaries(newPos) {
  return (
    newPos[0] >= 0 &&
    newPos[0] <= 60 - 1 &&
    newPos[1] >= 0 &&
    newPos[1] <= 60 - 1
  );
}

export function checkForMonster(newPos) {
  const currentMap = store.getState().player.map;

  const monsters = store.getState().monsters.components;

  let isMonster = false;

  Object.entries(monsters[currentMap]).forEach(([monsterId, value]) => {
    if (JSON.stringify(value.position) === JSON.stringify(newPos)) {
      isMonster = value.id;
    }
  });

  return isMonster;
}

export function checkForCharacter(newPos) {
  const currentMap = store.getState().player.map;

  const characters = store.getState().characters.components;

  if (!characters[currentMap]) {
    return;
  }

  let isCharacter = false;

  Object.entries(characters[currentMap]).forEach(([characterId, value]) => {
    if (JSON.stringify(value.position) === JSON.stringify(newPos)) {
      isCharacter = value.id;
    }
  });

  return isCharacter;
}

const canMove = (newPos) => {
  const currentMap = store.getState().player.map;

  const stats = store.getState().stats;

  const dataCurrentMap = maps[currentMap];

  const tiles = dataCurrentMap.tiles;

  const x = newPos[0];
  const y = newPos[1];

  if (tiles[y] !== undefined && tiles[y][x] !== undefined) {
    if (tiles[y][x] === 1) {
      return true;
    }
  }

  const check = tiles[y][x];

  const toMap =
    dataCurrentMap &&
    dataCurrentMap.entryPoints &&
    dataCurrentMap.entryPoints[check];

  if (toMap) {
    if (toMap.level) {
      if (toMap.level > stats.level) {
        errorMessage(
          "To enter this location, you need a level higher than" +
            " " +
            toMap.level
        );
        return false;
      }
    }

    changeMap(toMap);
  }

  return false;
};
