import React from "react";
import Reward from "./Reward";

const Complete = ({ reward_type, reward }) => {
  return (
    <>
      <div className="value_last value_center value_column">
        <Reward reward={reward} type={reward_type} />
      </div>
    </>
  );
};

export default Complete;
