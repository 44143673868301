import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
};

export const hintsSlice = createSlice({
  name: "hints",
  initialState,
  reducers: {
    removeHint(state, action) {
      state.data = state.data.filter((obj) => obj.id != action.payload);
    },
    addHint(state, action) {
      state.data.push(action.payload);
    },
  },
});

export const { removeHint, addHint } = hintsSlice.actions;

export default hintsSlice.reducer;
