import React from "react";
import styles from "./Icon.module.scss";

const Icon = ({ i, size, style }) => {
  return (
    <div
      style={{ ...{ width: `${size}px`, height: `${size}px` }, ...style }}
      className={`${styles.icon} ${styles[i]}`}
    ></div>
  );
};

export default Icon;
