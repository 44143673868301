import map from "./assets/map.jpg";

import miniMap from "./assets/minimap.png";

import preloadImage from "./assets/preload.jpg";

import tiles from "./tiles";

import characters from "./characters";

import monsters from "./monsters";

import music from "./assets/music_castle.mp3";

import avatar from "./assets/avatar.png";

const title = "Viula";

const description = "Viula, The Capital of the Kingdom of Edenlia";

const mapHeight = 3840;

const mapWidth = 3840;

const entryPoints = {
  5: { map: 2, position: [8, 30], level: 5 }, // вход в лес
  6: { map: 3, position: [12, 28], level: 10 }, // вход в подземелье
};

export default {
  map,
  miniMap,
  title,
  description,
  tiles,
  monsters,
  characters,
  mapHeight,
  mapWidth,
  entryPoints,
  preloadImage,
  music,
  avatar,
};
