import React from "react";
import styles from "./Coin.module.scss";
import { useSelector } from "react-redux";
import { animate } from "framer-motion";
import useSound from "use-sound";
import coinSound from "./assets/coin.mp3";
import Icon from "../../../components/icon";

const Coin = () => {
  const [coinPlay] = useSound(coinSound);

  const stats = useSelector((state) => state.stats);

  const [from, setFrom] = React.useState(false);

  const [to, setTo] = React.useState(false);

  const nodeRef = React.useRef();

  React.useEffect(() => {
    setFrom(stats.coin);
    setTo(stats.coin);
  }, []);

  React.useEffect(() => {
    setTo(stats.coin);
  }, [stats.coin]);

  React.useEffect(() => {
    const node = nodeRef.current;

    const enterAnimation = async () => {
      await animate(from, to, {
        duration: 1,
        onUpdate(value) {
          node.textContent = value.toFixed(0);
        },
      });

      setFrom(stats.coin);
    };

    if (from != to) {
      enterAnimation();

      coinPlay();
    }
  }, [from, to]);

  return (
    <div className={styles.bar}>
      <Icon
        i={"coin"}
        size={24}
        style={{ position: "absolute", zIndex: 999 }}
      />

      <div className={styles.body}></div>
      <div ref={nodeRef} className={styles.text} from={from} to={to}>
        {stats.coin}
      </div>
    </div>
  );
};

export default Coin;
