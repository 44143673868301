import stylesSeller from "./Knight.module.scss";
import avatar from "./dialog.png";
import spriteKnight from "./sprite.png";

const Seller = {
  type: "knight",
  name: "Gawain",
  description: "",
  styles: stylesSeller,
  avatar: avatar,
  sprite: spriteKnight,
};

export default Seller;
