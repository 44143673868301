import stylesAssasin from "./Assasin.module.scss";
import attackAssasin from "./sounds/attack.wav";
import walkAssasin from "./sounds/walk.mp3";
import spriteAssasin from "./sprite/assasinzip2.png";

const Assasin = {
  styles: stylesAssasin,
  attackSound: attackAssasin,
  walkSound: walkAssasin,
  sprite: spriteAssasin,
};

export default Assasin;
