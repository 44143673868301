import React from "react";
import Header from "../Header";
import { useDispatch, useSelector } from "react-redux";
import Bonus from "./Bonus";
import useTranslation from "../../utils/useTranslation";
import { fetchInviting } from "../../config/slices/bonus";
import InviteLink from "../../components/invite-link";

const Bonuses = () => {
  const [__trans] = useTranslation();

  const dispatch = useDispatch();

  const bonus = useSelector((state) => state.bonus);

  const daily = bonus.daily;

  const [category, setCategory] = React.useState(1);

  React.useEffect(() => {
    dispatch(fetchInviting());
  }, [dispatch]);

  return (
    <Header title="Bonuses">
      <div className="sidebar">
        <ul className="item">
          <li
            onClick={() => setCategory(1)}
            className={category == 1 ? "active" : ""}
          >
            <div className="icon_write_1 icon_md"></div>
            <div className="name">{__trans("Daily")}</div>
          </li>
          <li
            onClick={() => setCategory(2)}
            className={category == 2 ? "active" : ""}
          >
            <div className="icon_achievement icon_md"></div>
            <div className="name">{__trans("Achievements")}</div>
          </li>
        </ul>
      </div>

      {category == 1 && (
        <div className="block">
          <InviteLink />
          {daily &&
            daily.map((item, key) => <Bonus key={key} index={key} {...item} />)}
        </div>
      )}

      {category == 2 && <div className="block">in developing</div>}
    </Header>
  );
};

export default Bonuses;
