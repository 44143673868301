import { useAnimate } from "framer-motion";
import React from "react";
import { useDispatch } from "react-redux";
import styles from "./Hint.module.scss";
import { removeHint, removeMessage } from "../reducer";
import { motion } from "framer-motion";

const Hint = ({ data }) => {
  const dispatch = useDispatch();

  const [scope, animate] = useAnimate();

  const notificationRef = React.useRef();

  const deleteMessage = () => {
    setTimeout(() => {
      dispatch(removeHint(data.id));
    }, data.lifetime * 1000);
  };

  React.useEffect(() => {
    const enterAnimation = async () => {
      await animate(
        notificationRef.current,
        { opacity: 1, y: -25 },
        { duration: 0.5 }
      );

      await animate(notificationRef.current, { opacity: 0 }, { duration: 0.5 });

      deleteMessage();
    };
    enterAnimation();
  }, []);

  return (
    <>
      <motion.div
        ref={notificationRef}
        initial={{ opacity: 0, y: 0 }}
        style={{
          position: "absolute",
          top: data.position[1] * 60,
          left: data.position[0] * 60,
        }}
        className={styles.gold}
      >
        <div className={styles.notify}></div>
        <div className={styles.numbers}>+{data.value}</div>
      </motion.div>
    </>
  );
};

export default Hint;
