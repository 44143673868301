import React from "react";
import styles from "./Message.module.scss";
import { motion } from "framer-motion";
import { removeMessage } from "../reducer";
import { useDispatch } from "react-redux";

const Message = ({ data }) => {
  const dispatch = useDispatch();

  const deleteMessage = () => {
    setTimeout(() => {
      dispatch(removeMessage(data.id));
    }, data.lifetime * 1000);
  };

  React.useEffect(() => {
    deleteMessage();
  }, []);

  let type = styles.info;

  switch (data.type) {
    case "error":
      type = styles.error;
      break;
  }

  if (!type) {
    return false;
  }

  return (
    <>
      <motion.div
        style={{ width: "400px" }}
        className={styles.message}
        initial={{ opacity: 0, y: -25 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.2 }}
      >
        <div className={type}>
          <div className={styles.top}></div>
          <div className={styles.body}>
            <div className={styles.text}>{data.text}</div>
          </div>
          <div className={styles.bottom}></div>
        </div>
      </motion.div>
    </>
  );
};

export default Message;
