import React from "react";
import Character from "./Character";
import { useSelector } from "react-redux";

const Characters = () => {
  const characters = useSelector((state) => state.characters);

  const [charactersToRender, setCharactersToRender] = React.useState(null);

  const { map } = useSelector((state) => state.player);

  React.useEffect(() => {
    const characterArray = [];

    if (characters.components) {
      if (JSON.stringify(characters.components) === JSON.stringify({})) {
        setCharactersToRender(null);
      } else if (characters.components[map]) {
        Object.keys(characters.components[map]).forEach((uuid) => {
          characterArray.push(
            <Character
              key={uuid}
              character={characters.components[map][uuid]}
            />
          );
        });

        setCharactersToRender(characterArray);
      }
    }
  }, [characters]);

  return charactersToRender;
};

export default Characters;
