import React from "react";
import { useSelector } from "react-redux";
import Hint from "./Hint";

const Hints = () => {
  const [dataToRender, setDataToRender] = React.useState(null);

  const data = useSelector((state) => state.hints.data);

  React.useEffect(() => {
    const array = [];

    data.map((obj) => {
      array.push(<Hint key={obj.id} data={obj} />);
    });

    setDataToRender(array);
  }, [data]);

  return dataToRender;
};

export default Hints;
