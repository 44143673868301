import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  page: "home",
  popup: false,
  paused: false,
  serverOff: false,
};

export const menuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {
    changePage(state, action) {
      state.page = action.payload;
    },
    changePaused(state, action) {
      state.paused = action.payload;
    },
    changePopup(state, action) {
      state.popup = action.payload;
    },
    changeServerOff(state, action) {
      state.serverOff = action.payload;
    },
  },
});

export const { changePage, changePaused, changePopup, changeServerOff } =
  menuSlice.actions;

export default menuSlice.reducer;
