import { store } from "../../../config/store";
import createHint from "../../Hints/actions/create-hint";
import {
  damageToMonster,
  deleteMonster,
  diedMonster,
} from "../../Monsters/reducer";
import { bloodMonster } from "../../Spells/action/spellSets";
import { getCoin, getExp, getMultiplier } from "../../Stats/reducer";
import { checkForMonster, getNewPosition } from "./movePlayer";

export default function attackMonster() {
  // get player direction and the location of position to attack
  const { position, direction, map } = store.getState().player;

  const currentMap = map;

  const newPos = getNewPosition(position, direction);

  const monsterId = checkForMonster(newPos);

  if (monsterId) {
    const { monsters, stats } = store.getState();

    // get monster
    const currMonster = monsters.components[currentMap][monsterId];
    const monsterPos = currMonster.position;

    const playerDamage = stats.damage;

    store.dispatch(
      damageToMonster({
        id: currMonster.id,
        map: currentMap,
        damage: playerDamage,
      })
    );

    // check if monster died
    if (currMonster.hp - playerDamage <= 0 && !currMonster.died) {
      const multiplier = getMultiplier(store.getState());

      store.dispatch(diedMonster({ id: currMonster.id, map: currentMap }));

      setTimeout(() => {
        let reward = currMonster.coin;

        if (multiplier > 1) {
          reward = reward * multiplier;
        }
        createHint(newPos, reward, "gold");

        store.dispatch(getExp(currMonster.exp));

        store.dispatch(getCoin(reward));

        store.dispatch(deleteMonster({ id: currMonster.id, map: currentMap }));
      }, 1000);
    }

    bloodMonster(monsterPos);
  }
}
