import React from "react";
import { useSelector } from "react-redux";
import styles from "../Minimap.module.scss";

const Characters = () => {
  const characters = useSelector((state) => state.characters);

  const { map } = useSelector((state) => state.player);

  const getStyle = (type) => {
    switch (type) {
      case "healer":
        return styles.healer;
      case "seller":
        return styles.seller;
      case "knight":
        return styles.helper;
    }
  };

  return (
    <>
      {Object.entries(characters.components[map]).map(([key, value]) => {
        return (
          <div
            key={key}
            style={{
              left: value.position[0] * 6,
              top: value.position[1] * 6,
            }}
            className={getStyle(value.type)}
          ></div>
        );
      })}
    </>
  );
};

export default Characters;
