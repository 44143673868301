import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  language: "en",
  sound: 20,
  music: 20,
  firstLaunch: true,
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    changeSound(state, action) {
      state.sound = action.payload;
    },

    changeMusic(state, action) {
      state.music = action.payload;
    },

    changeLanguage(state, action) {
      state.language = action.payload;
    },

    completeFirstLaunch(state, action) {
      state.firstLaunch = false;
    },
  },
});

export const { changeSound, changeMusic, changeLanguage, completeFirstLaunch } =
  settingsSlice.actions;

export default settingsSlice.reducer;
