import React from "react";
import styles from "./Settings.module.scss";
import Range from "../../components/range";
import { useDispatch, useSelector } from "react-redux";

import LanguageButton from "../Language/LanguageButton";
import Header from "../Header";
import { changeMusic, changeSound } from "../../config/slices/settings";

const Settings = () => {
  const dispatch = useDispatch();

  const settings = useSelector((state) => state.settings);

  const setSound = (value) => {
    dispatch(changeSound(value));
  };

  const setMusic = (value) => {
    dispatch(changeMusic(value));
  };

  return (
    <Header title="Settings">
      <div className={styles.content}>
        <div className={styles.block}>
          <ul className={styles.item}>
            <li>
              <div className={styles.name}>
                {settings.sound > 0 ? (
                  <div className={styles.icon_sound}></div>
                ) : (
                  <div className={styles.icon_no_sound}></div>
                )}
                Sound Fx
              </div>

              <div className={styles.range}>
                <div className={styles.value}>{settings.sound}</div>
                <Range
                  start={settings.sound}
                  setParam={(param) => setSound(param)}
                />
                <div className={styles.max}>100</div>
              </div>
            </li>

            <li>
              <div className={styles.name}>
                {settings.music > 0 ? (
                  <div className={styles.icon_music}></div>
                ) : (
                  <div className={styles.icon_no_music}></div>
                )}
                Music
              </div>

              <div className={styles.range}>
                <div className={styles.value}>{settings.music}</div>
                <Range
                  start={settings.music}
                  setParam={(param) => setMusic(param)}
                />

                <div className={styles.max}>100</div>
              </div>
            </li>
          </ul>
        </div>

        <div className={styles.block}>
          <ul className={styles.item}>
            <li>
              <LanguageButton />
            </li>
          </ul>
        </div>
      </div>
    </Header>
  );
};

export default Settings;
