import Healer from "./healer";
import Seller from "./seller";
import Knight from "./knight";

const characters = {
  healer: Healer,
  seller: Seller,
  knight: Knight,
};

export default characters;
