import React from "react";
import { useSelector } from "react-redux";
import Spell from "./Spell";

const Spells = () => {
  const [spellsToRender, setSpellsToRender] = React.useState(null);
  const data = useSelector((state) => state.spells.data);
  React.useEffect(() => {
    const spellArray = [];
    data.map((item) => {
      if (item.status == true) {
        spellArray.push(<Spell key={item.id} data={item} />);
      }
    });
    setSpellsToRender(spellArray);
  }, [data]);
  return spellsToRender;
};

export default Spells;
