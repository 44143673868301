import { createSlice } from "@reduxjs/toolkit";
import uuidv4 from "../../utils/uuid-v4";
import characterData from "../../data/characters";

const initialState = {
  components: {},
};

export const charactersSlice = createSlice({
  name: "characters",
  initialState,
  reducers: {
    addCharacters(state, action) {
      if (!state.components[action.payload.map]) {
        state.components[action.payload.map] = {};
        action.payload.characters.forEach((character) => {
          const uuid = uuidv4();
          character = {
            id: uuid,
            direction: "down",
            position: character.position.map((value) => value),
            ...characterData[character.type],
          };
          state.components[action.payload.map][uuid] = character;
        });
      }
    },
    changeDirection(state, action) {
      state.components[action.payload.map][action.payload.id].direction =
        action.payload.direction;
    },
  },
});

export const { addCharacters, changeDirection } = charactersSlice.actions;

export default charactersSlice.reducer;
