import React from "react";
import styles from "./Monsters.module.scss";
import { useSelector } from "react-redux";
import useTranslation from "../../../utils/useTranslation";
import Icon from "../../../components/icon";

const Monsters = () => {
  const [__trans] = useTranslation();

  const [count, setCount] = React.useState(0);

  const currentMap = useSelector((state) => state.player.map);

  const monsters = useSelector((state) => state.monsters);

  React.useEffect(() => {
    const num = Object.keys(monsters.components[currentMap]).length;

    setCount(num);
  }, [monsters.components]);

  return (
    <div className={styles.frame}>
      <Icon i={"ticket"} size={28} />
      <div className={styles.text}>
        <div>{__trans("Monsters")}</div>
        <div>
          {__trans("Remaining")} {count}
        </div>
      </div>
    </div>
  );
};

export default Monsters;
