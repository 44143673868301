import { store } from "../../../config/store";
import {
  errorMessage,
  infoMessage,
} from "../../Messages/actions/message-manager";
import { getToken, loseCoin } from "../../Stats/reducer";

const buyToken = (token) => {
  const stats = store.getState().stats;

  const rate = 1000; // 1 к 100

  const total = rate * token;

  if (stats.coin < total) {
    errorMessage("Недостаточно золота!");

    return false;
  }

  store.dispatch(loseCoin(total));

  store.dispatch(getToken(token));

  infoMessage("Сделка совершена!");
};

export default buyToken;
