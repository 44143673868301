import React from "react";
import { store } from "../../../config/store";
import { attackMonster, changeDirection, positionToMonster } from "../reducer";
import { radiusAttack } from "../../../utils/monster-attack-radius";
import attackPlayer from "./attackPlayer";

function playerInRange(playerPos, monsterPos) {
  let inRange = false;

  // for each tile around the monster
  radiusAttack(1).forEach((tile) => {
    // // add the monsters location
    const offsetX = tile.x + monsterPos[0];
    const offsetY = tile.y + monsterPos[1];

    if (JSON.stringify([offsetX, offsetY]) === JSON.stringify(playerPos)) {
      inRange = true;
    }
  });
  return inRange;
}

export default function takeMonsters() {
  const monsters = store.getState().monsters;

  const player = store.getState().player;

  const currentMap = store.getState().player.map;

  Object.entries(monsters.components[currentMap]).forEach(
    ([monsterId, value]) => {
      // get monster id and position
      const { position, damage, died } = value;

      if (playerInRange(player.position, position) && !died) {
        attackPlayer(damage);

        if (value.spriteTypes.indexOf("attack") === 0) {
          store.dispatch(
            attackMonster({
              map: currentMap,
              id: monsterId,
              value: true,
            })
          );
        }

        // get distance from player on both axis
        const xDiff = position[0] - player.position[0];
        const yDiff = position[1] - player.position[1];
        const greaterY = Math.abs(yDiff) > Math.abs(xDiff);

        // see if y axis is greater distance from player
        if (greaterY) {
          // if the monster is mostly below the player on the y axis
          if (yDiff > 0) {
            // move the monster 'up' relatively

            store.dispatch(
              changeDirection({
                map: currentMap,
                id: monsterId,
                direction: "up",
              })
            );
          }
          // if the monster is mostly above the player on the y axis
          else if (yDiff < 0) {
            // move the monster 'down' relatively

            store.dispatch(
              changeDirection({
                map: currentMap,
                id: monsterId,
                direction: "down",
              })
            );
          }
        } // x axis is greater distance from player
        else {
          // if the monster is mostly to the right of the player
          if (xDiff > 0) {
            // move the monster 'left' relatively

            store.dispatch(
              changeDirection({
                map: currentMap,
                id: monsterId,
                direction: "left",
              })
            );
          }
          // if the monster is mostly to the left of the player
          else if (xDiff < 0) {
            // move the monster 'right' relatively

            store.dispatch(
              changeDirection({
                map: currentMap,
                id: monsterId,
                direction: "right",
              })
            );
          }
        }
      } else {
        if (value.spriteTypes.indexOf("attack") === 0) {
          store.dispatch(
            attackMonster({
              map: currentMap,
              id: monsterId,
              value: false,
            })
          );
        }

        // const xDiff = position[0] - 1;
        // const yDiff = position[1];
        // store.dispatch(
        //   positionToMonster({
        //     map: currentMap,
        //     id: monsterId,
        //     position: [xDiff, yDiff],
        //   })
        // );
      }
    }
  );
}
