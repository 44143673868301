import React from "react";
import styles from "./Minimap.module.scss";
import { useDispatch } from "react-redux";
import { openMiniMap } from "../Dialogs/reducer";
import Icon from "../../components/icon";

const Minimap = () => {
  const dispatch = useDispatch();

  const open = () => {
    dispatch(openMiniMap());
  };
  return (
    <>
      <div className={styles.left_menu}>
        <div onClick={() => open()} className={styles.test2}>
          <Icon i={"map"} size={24} />
        </div>
      </div>
    </>
  );
};

export default Minimap;
