import React from "react";
import styles from "./Level.module.scss";
import { useSelector } from "react-redux";

const Level = () => {
  const stats = useSelector((state) => state.stats);

  const progressRef = React.useRef();

  const setWidth = (width) => {
    progressRef.current.style.background =
      "linear-gradient(to right, #73880A 0%, #73880A " +
      width +
      "%, #000 " +
      width +
      "%, #000 100%)";
  };

  React.useEffect(() => {
    const width = (stats.exp / stats.expToLevel) * 100;

    setWidth(width);
  }, [stats]);

  return (
    <div className={styles.level}>
      <div className={styles.icon}>
        <div className={styles.number}>{stats.level}</div>
      </div>

      <div ref={progressRef} className={styles.body}>
        <div className={styles.progress}></div>

        <div className={styles.info}>
          {stats.exp} /{stats.expToLevel}
        </div>
      </div>
    </div>
  );
};

export default Level;
