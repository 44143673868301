import React from "react";
import styles from "./Range.module.scss";

const Range = ({ start, setParam }) => {
  const rangeRef = React.useRef();

  const [value, setValue] = React.useState(start);

  const settings = (p) => {
    setParam(p);
    setValue(p);
  };

  const changeBackground = () => {
    rangeRef.current.style.background =
      "linear-gradient(to right, #fab037 0%, #fab037 " +
      rangeRef.current.value +
      "%, #000 " +
      rangeRef.current.value +
      "%, #000 100%)";
  };

  React.useEffect(() => {
    changeBackground();
  }, []);

  return (
    <input
      ref={rangeRef}
      onInput={changeBackground}
      className={styles.range}
      type="range"
      min="0"
      max="100"
      value={value}
      onChange={(e) => settings(e.target.value)}
    />
  );
};

export default Range;
