import agroBee from "./sound/beeagro.wav";
import attackBee from "./sound/beeattack.wav";
import deathBee from "./sound/beedeath.wav";
import stylesViking from "./Bat.module.scss";
import spriteBee from "./sprite/bat.png";
import avatar from "./sprite/avatar.png";

const Viking = {
  hp: 90,
  maxHp: 90,
  damage: 15,
  exp: 15,
  coin: 175,
  type: "bat",
  styles: stylesViking,
  agroSound: agroBee,
  attackSound: attackBee,
  deathSound: deathBee,
  sprite: spriteBee,
  spriteTypes: [],
  avatar,
};

export default Viking;
