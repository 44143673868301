import stylesHealer from "./Healer.module.scss";
import spriteHealer from "./sprite/healersprite.png";
import avatar from "./dialog.png";

const Healer = {
  type: "healer",
  name: "Tauriel",
  description: "",
  styles: stylesHealer,
  sprite: spriteHealer,
  avatar: avatar,
};

export default Healer;
