import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  type: false,
  open: false,
};

export const dialogSlice = createSlice({
  name: "dialog",
  initialState,
  reducers: {
    changeOpenState(state, action) {
      state.type = action.payload.type;
      state.open = action.payload.open;
    },

    closeDialog(state) {
      state.type = false;
      state.open = false;
    },
    openDialog(state, action) {
      state.type = action.payload;
      state.open = true;
    },
  },
});

export const { closeDialog, openDialog, changeOpenState } = dialogSlice.actions;

export default dialogSlice.reducer;
