import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
};

export const spellsSlice = createSlice({
  name: "spells",
  initialState,
  reducers: {
    addSpell(state, action) {
      state.data.push(action.payload);
    },
    deleteSpell(state, action) {
      state.data = state.data.filter((obj) => obj.id != action.payload);
    },
  },
});

export const { addSpell, deleteSpell } = spellsSlice.actions;

export default spellsSlice.reducer;
