import agroBee from "./sound/beeagro.wav";
import attackBee from "./sound/beeattack.wav";
import deathBee from "./sound/beedeath.wav";
import stylesBee from "./Bee.module.scss";
import spriteBee from "./sprite/bee.png";
import avatar from "./sprite/avatar.png";

const Bee = {
  hp: 60,
  maxHp: 60,
  damage: 5,
  exp: 10, // сколько опыта получаем
  coin: 50, // сколько монет получаем
  type: "bee",
  styles: stylesBee,
  agroSound: agroBee,
  attackSound: attackBee,
  deathSound: deathBee,
  sprite: spriteBee,
  spriteTypes: [],
  avatar,
};

export default Bee;
