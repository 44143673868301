const monsters = [
  {
    type: "bee",
    position: [30, 41],
  },
  {
    type: "bee",
    position: [31, 15],
  },
  {
    type: "bee",
    position: [52, 33],
    direction: "down",
  },
  {
    type: "bee",
    position: [8, 31],
    direction: "down",
  },
  {
    type: "bee",
    position: [45, 26],
    direction: "down",
  },
  {
    type: "bee",
    position: [42, 41],
  },

  {
    type: "bee",
    position: [19, 27],
  },

  {
    type: "bee",
    position: [19, 28],
  },
  {
    type: "bee",
    position: [18, 51],
  },
  {
    type: "bee",
    position: [16, 39],
  },
  {
    type: "bee",
    position: [19, 21],
  },
  {
    type: "bee",
    position: [31, 7],
    direction: "down",
  },
  {
    type: "bee",
    position: [42, 16],
  },
  {
    type: "bee",
    position: [32, 46],
  },
  {
    type: "viking",
    position: [41, 53],
  },
  {
    type: "viking",
    position: [51, 41],
  },
  {
    type: "viking",
    position: [16, 14],
    direction: "down",
  },
  {
    type: "viking",
    position: [24, 41],
    direction: "up",
  },
  {
    type: "skeleton",
    position: [53, 12],
  },
  {
    type: "skeleton",
    position: [54, 12],
  },
  {
    type: "boar",
    position: [56, 45],
  },
  {
    type: "boar",
    position: [54, 31],
    direction: "right",
  },
  {
    type: "boar",
    position: [8, 41],
    direction: "right",
  },
  {
    type: "boar",
    position: [54, 32],
    direction: "right",
  },

  {
    type: "boar",
    position: [16, 27],
    direction: "right",
  },
  {
    type: "boar",
    position: [16, 28],
    direction: "right",
  },
  {
    type: "boar",
    position: [8, 25],
    direction: "right",
  },
  {
    type: "boar",
    position: [7, 51],
    direction: "right",
  },
  {
    type: "boar",
    position: [26, 53],
  },
  {
    type: "boar",
    position: [21, 9],
    direction: "right",
  },
  {
    type: "bat",
    position: [54, 15],
  },
];

export default monsters;
