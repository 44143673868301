import { createSlice } from "@reduxjs/toolkit";
import uuidv4 from "../../utils/uuid-v4";
import monsterData from "../../data/monsters";

const initialState = {
  components: {},
};

export const monstersSlice = createSlice({
  name: "monsters",
  initialState,
  reducers: {
    addMonsters(state, action) {
      if (
        !state.components[action.payload.map] ||
        JSON.stringify(state.components[action.payload.map]) ===
          JSON.stringify({})
      ) {
        state.components[action.payload.map] = {};

        action.payload.monsters.forEach((monster) => {
          const uuid = uuidv4();
          monster = {
            id: uuid,
            attack: false,
            died: false,
            direction: monster.direction || "left",
            position: monster.position.map((value) => value),
            ...monsterData[monster.type],
          };
          state.components[action.payload.map][uuid] = monster;
        });
      }
    },

    attackMonster(state, action) {
      state.components[action.payload.map][action.payload.id].attack =
        action.payload.value;
    },

    diedMonster(state, action) {
      state.components[action.payload.map][action.payload.id].died = true;
    },

    damageToMonster(state, action) {
      state.components[action.payload.map][action.payload.id].hp -=
        action.payload.damage;
    },

    deleteMonster(state, action) {
      delete state.components[action.payload.map][action.payload.id];
    },

    changeDirection(state, action) {
      state.components[action.payload.map][action.payload.id].direction =
        action.payload.direction;
    },

    positionToMonster(state, action) {
      state.components[action.payload.map][action.payload.id].position =
        action.payload.position;
    },

    removeItem(state, action) {
      // state.monsters = state.monsters.filter((obj) => obj.id != action.payload);
    },

    changeMonsterAttack(state, action) {
      // const findItem = state.monsters.find(
      //   (obj) => obj.id == action.payload.id
      // );
      // if (findItem) {
      //   findItem.attack = action.payload.value;
      // }
    },

    changeMonsterAggravation(state, action) {
      // const findItem = state.monsters.find(
      //   (obj) => obj.id == action.payload.id
      // );
      // if (findItem) {
      //   findItem.aggravation = action.payload.value;
      // }
    },

    changeDead(state, action) {
      // const findItem = state.monsters.find((obj) => obj.id == action.payload);
      // if (findItem) {
      //   findItem.dead = true;
      // }
    },

    changeAggravation(state, action) {
      // const findItem = state.monsters.find(
      //   (obj) => obj.id == action.payload.id
      // );
      // if (findItem) {
      //   findItem.aggravation = action.payload.value;
      // }
    },

    changeHealth(state, action) {
      // const findItem = state.monsters.find(
      //   (obj) => obj.id == action.payload.id
      // );
      // if (findItem) {
      //   findItem.health += action.payload.value;
      // }
    },

    changePosition(state, action) {
      // const findItem = state.monsters.find(
      //   (obj) => obj.id == action.payload.id
      // );
      // if (findItem) {
      //   findItem.x = action.payload.x;
      //   findItem.y = action.payload.y;
      // }
    },

    changeNewPosition(state, action) {
      // const findItem = state.monsters.find(
      //   (obj) => obj.id == action.payload.id
      // );
      // if (findItem) {
      //   findItem.new_x += action.payload.x;
      //   findItem.new_y += action.payload.y;
      // }
    },
  },
});

export const {
  positionToMonster,
  changeHealth,
  changePosition,
  removeItem,
  changeDead,
  changeNewPosition,
  changeMonsterAttack,
  changeMonsterAggravation,
  changeAggravation,
  addMonsters,
  damageToMonster,
  attackMonster,
  diedMonster,
  deleteMonster,
  changeDirection,
} = monstersSlice.actions;

export default monstersSlice.reducer;
