import React from "react";
import Progress from "../../../components/progress";
import Reward from "./Reward";
const Active = ({ total, need, reward, reward_type }) => {
  const width = (total / need) * 100;

  return (
    <>
      <div className="value_last value_center value_gap">
        <Progress width={width} />
        {total} / {need}
      </div>
      <div className="value_last">
        <Reward reward={reward} type={reward_type} />
      </div>
    </>
  );
};

export default Active;
