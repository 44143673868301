import { createSlice } from "@reduxjs/toolkit";
import { TELEGRAM_BOT } from "../../config/constants";

const initialState = {
  name: "user",
  coin: 551,
  token: 1,
  hp: 200,
  maxHp: 200,

  monsters: 0,

  level: 1,
  exp: 0,
  expToLevel: 100,
  damage: 30,

  levelUp: { hp: 0, damage: 0 },
};

export const statsSlice = createSlice({
  name: "stats",
  initialState,
  reducers: {
    getCoin(state, action) {
      state.coin += action.payload;
    },
    loseCoin(state, action) {
      state.coin -= action.payload;
    },

    getToken(state, action) {
      state.token += Number(action.payload);
    },
    loseToken(state, action) {
      state.token -= action.payload;
    },

    getExp(state, action) {
      state.exp += 55;

      const newTotalExp = state.exp + action.payload;
      const { expToLevel } = state;

      // if they are leveling up
      if (newTotalExp >= expToLevel) {
        // increment level
        state.level += 1;

        // calculate leftover exp if it isn't exactly enough
        if (!(state.exp === expToLevel)) {
          const leftoverExp = newTotalExp % expToLevel;
          state.exp = leftoverExp;
        }

        state.expToLevel = Math.floor(state.expToLevel * 1.5);

        const moreHp = Math.floor(Math.random() * 5) + 1;
        state.hp += moreHp;
        state.maxHp += moreHp;
        state.levelUp.hp = moreHp;

        // get more damage (+1)
        let moreDmg = 1;
        state.damage += moreDmg;
        state.levelUp.dmg = moreDmg;
      } else {
        // they aren't leveling up
        state.exp += action.payload;
      }
    },

    healHp(state, action) {
      state.hp = state.maxHp;
    },

    damageToPlayer(state, action) {
      state.hp -= action.payload;
    },
  },
});

export const {
  getCoin,
  loseCoin,
  getToken,
  loseToken,
  getExp,
  damageToPlayer,
  healHp,
} = statsSlice.actions;

export default statsSlice.reducer;

export const selectInviteLink = (state) => {
  return `${TELEGRAM_BOT}?start=${state.stats.inviteLink}`;
};

export const getMultiplier = (state) => {
  return 1;
};
