import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  position: [25, 37],
  px: false,
  map: 1,
  direction: "down",
  playerMoved: false,
  playerAttacked: false,
};

export const playerSlice = createSlice({
  name: "player",
  initialState,
  reducers: {
    changePxPosition(state, action) {
      switch (action.payload) {
        case "left":
          state.px = [state.px[0] - 1, state.px[1]];
          break;
        case "right":
          state.px = [state.px[0] + 1, state.px[1]];
          break;
        case "up":
          state.px = [state.px[0], state.px[1] - 1];
          break;
        case "down":
          state.px = [state.px[0], state.px[1] + 1];
          break;
      }
    },

    changePixelPosition(state, action) {
      state.px = action.payload;
    },

    changePosition(state, action) {
      state.position = action.payload;
    },

    // меняем позицию
    changePlayerPosition(state, action) {
      state.position[action.payload.property] = state.position[
        action.payload.property
      ] += Number(action.payload.change);
    },

    // направление
    changePlayerDirection(state, action) {
      state.direction = action.payload;
    },

    // меняем монеты
    changeCoin(state, action) {
      state.coin += action.payload;
    },

    // меняем монеты
    changePlayerHealth(state, action) {
      state.health += action.payload;
    },

    setMap(state, action) {
      state.map = action.payload;
    },

    setPlayerMoved(state, action) {
      state.playerMoved = action.payload;
    },

    setPlayerAttacked(state, action) {
      state.playerAttacked = action.payload;
    },
  },
});

export const {
  changePxPosition,
  changePlayerPosition,
  changePlayerDirection,
  changePlayerHealth,
  setPlayerMoved,
  setPlayerAttacked,
  changeCoin,

  changePosition,

  changePixelPosition,
  setMap,
} = playerSlice.actions;

export default playerSlice.reducer;
