import React from "react";

const Character = ({ character }) => {
  const [sprite, setSprite] = React.useState("down");

  React.useEffect(() => {
    setSprite(character.direction);
  }, [character.direction]);

  return (
    <div
      style={{
        width: "60px",
        height: "60px",
        position: "absolute",
        overflow: "hidden",
        //border: "1px solid red",
        left: character.position[0] * 60,
        top: character.position[1] * 60,
      }}
    >
      <div
        style={{
          backgroundImage: `url(${character.sprite})`,
        }}
        facing={sprite}
        className={character.styles.spritesheet}
      ></div>
    </div>
  );
};

export default Character;
