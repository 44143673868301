export const radiusAttack = (radiusSize = 1) => {
  let x, y;
  const radTiles = [];
  // calculate a tile map with desired radius
  // (results in array = [[-4,-4]...[0,0]...[4,4]] )
  for (y = -radiusSize; y <= radiusSize; y++) {
    for (x = -radiusSize; x <= radiusSize; x++) {
      if (x * x + y * y <= radiusSize * radiusSize) {
        radTiles.push({ x, y });
      }
    }
  }
  return radTiles;
};
