import { combineReducers, configureStore } from "@reduxjs/toolkit";

import player from "../features/Player/reducer";
import spells from "../features/Spells/reducer";
import dialog from "../features/Dialog/reducer";
import characters from "../features/Characters/reducer";
import messages from "../features/Messages/reducer";
import monsters from "../features/Monsters/reducer";
import stats, { updateStats } from "../features/Stats/reducer";
import dialogs from "../features/Dialogs/reducer";
import hints from "../features/Hints/reducer";
import preload from "../features/Preload/reducer";
import settings from "./slices/settings";
import menu from "./slices/menu";
import bonus from "./slices/bonus";
import viewport from "../features/Viewport/reducer";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import createTransform from "redux-persist/lib/createTransform";
import CryptoJS from "crypto-js";

const logger = (store) => (next) => (action) => {
  if (!action.type.startsWith("stats/")) {
    return next(action);
  }
  next(action);
};

const encrypt = createTransform(
  (inboundState, key) => {
    if (!inboundState) return inboundState;
    const cryptedText = CryptoJS.AES.encrypt(
      JSON.stringify(inboundState),
      "secret key 123"
    );

    return cryptedText.toString();
  },
  (outboundState, key) => {
    if (!outboundState) return outboundState;
    const bytes = CryptoJS.AES.decrypt(outboundState, "secret key 123");
    const decrypted = bytes.toString(CryptoJS.enc.Utf8);

    return JSON.parse(decrypted);
  }
);

const persistConfig = {
  key: "root",
  storage: storage,

  blacklist: [
    "menu",
    "stats",
    "viewport",
    "spells",
    "dialog",
    "dialogs",
    "messages",
    "hints",
    "preload",
    "monsters",
    "characters",
    "dialog",
  ],
  transforms: [encrypt],
};

const rootReducer = combineReducers({
  player,
  menu,
  spells,
  dialog,
  characters,
  messages,
  settings,
  monsters,
  stats,
  dialogs,
  hints,
  preload,
  bonus,
  viewport,
});

// Middleware: Redux Persist Persisted Reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  devTools: false,
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
});

export const persistor = persistStore(store);
