import React from "react";
import styles from "./Minimap.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { closeMiniMap } from "../reducer";
import Enemies from "./enemies";
import maps from "../../../data/maps";
import Characters from "./characters";
import useTranslation from "../../../utils/useTranslation";

const MiniMap = () => {
  const [__trans] = useTranslation();

  const player = useSelector((state) => state.player);

  const currentMap = player.map;

  const rootRef = React.useRef();

  const mapRef = React.useRef();

  React.useEffect(() => {
    const dataCurrentMap = maps[currentMap];

    mapRef.current.style.backgroundImage = `url(${dataCurrentMap.miniMap})`;
  }, []);

  React.useEffect(() => {
    rootRef.current.scrollLeft = player.position[0] * 3 - 6;
    rootRef.current.scrollTop = player.position[1] * 3 - 6;
  }, [player.position]);

  const dispatch = useDispatch();

  const close = () => {
    dispatch(closeMiniMap());
  };

  return (
    <>
      <div className={styles.popup}>
        <div className={styles.frame}>
          <div onClick={() => close()} className={styles.close}></div>
          <div className={styles.icon_move}></div>
          <div ref={rootRef} className={`${styles.body} scrollnoshow`}>
            <div ref={mapRef} className={styles.map}>
              <Enemies />
              <Characters />
              <div
                style={{
                  left: player.position[0] * 6,
                  top: player.position[1] * 6,
                }}
                className={styles.point}
              ></div>
            </div>
          </div>
          <div className={styles.help}>
            <div className={styles.value}>
              <div className={styles.help_point}></div>{" "}
              {__trans("Your location")}
            </div>
            <div className={styles.value}>
              <div className={styles.help_healer}></div> {__trans("Healer")}
            </div>
            <div className={styles.value}>
              <div className={styles.help_seller}></div>{" "}
              {__trans("Seller of magic stones")}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MiniMap;
