import React from "react";

import styles from "./Health.module.scss";

const Health = ({ value, max }) => {
  // hide hp bars on full health units or died
  if (value == max || value <= 0) {
    return;
  }

  const left = (value / max) * 100;

  return (
    <div className={styles.healthbar}>
      <div className={styles.bar} style={{ width: left + "%" }}>
        <div className={styles.hit}></div>
      </div>
    </div>
  );
};

export default Health;
