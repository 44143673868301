const monsters = [
  {
    type: "viking",
    position: [44, 28],
    direction: "down",
  },
  {
    type: "viking",
    position: [45, 28],
    direction: "down",
  },
  {
    type: "viking",
    position: [46, 28],
    direction: "down",
  },
  {
    type: "viking",
    position: [47, 29],
  },

  {
    type: "viking",
    position: [9, 21],
    direction: "down",
  },

  {
    type: "viking",
    position: [9, 30],
  },
  {
    type: "viking",
    position: [18, 30],
  },
  {
    type: "viking",
    position: [13, 9],
    direction: "right",
  },
  {
    type: "viking",
    position: [25, 12],
    direction: "right",
  },
  {
    type: "viking",
    position: [25, 24],
    direction: "right",
  },
  {
    type: "viking",
    position: [34, 30],
    direction: "right",
  },
  {
    type: "viking",
    position: [15, 47],
    direction: "up",
  },
  {
    type: "viking",
    position: [13, 45],
    direction: "right",
  },
  {
    type: "viking",
    position: [31, 51],
    direction: "right",
  },
  {
    type: "viking",
    position: [48, 48],
    direction: "up",
  },
  {
    type: "viking",
    position: [42, 54],
  },
  {
    type: "viking",
    position: [54, 54],
  },
  {
    type: "bee",
    position: [12, 32],
    direction: "up",
  },
  {
    type: "bee",
    position: [18, 18],
  },
  {
    type: "bee",
    position: [45, 39],
  },
  {
    type: "bee",
    position: [33, 45],
  },
  {
    type: "bee",
    position: [27, 38],
  },
  {
    type: "boar",
    position: [22, 30],
  },

  {
    type: "bee",
    position: [18, 35],
    direction: "up",
  },

  {
    type: "skeleton",
    position: [44, 12],
  },

  {
    type: "skeleton",
    position: [33, 12],
    direction: "down",
  },

  {
    type: "bat",
    position: [39, 14],
    direction: "up",
  },

  {
    type: "bat",
    position: [33, 16],
    direction: "down",
  },
];

export default monsters;
