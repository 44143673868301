import React from "react";
import GameOver from "./game-over";
import MiniMap from "./mini-map";
import { useSelector } from "react-redux";

const Dialogs = () => {
  const dialogs = useSelector((state) => state.dialogs);

  if (dialogs.miniMap == true) {
    return <MiniMap />;
  }

  if (dialogs.gameOver == true) {
    return <GameOver />;
  }
};

export default Dialogs;
