import React from "react";
import Header from "../Header";
import axios from "axios";
import Number from "./Number";
import { useSelector } from "react-redux";
import useTranslation from "../../utils/useTranslation";
import { SERVER_URL } from "../../config/constants";
import Icon from "../../components/icon";

const Rating = () => {
  const [__trans] = useTranslation();

  const stats = useSelector((state) => state.stats);

  const [data, setData] = React.useState([]);
  const [offset, setOffset] = React.useState(0);

  const [sort, setSort] = React.useState("token");

  const contentRef = React.useRef(false);

  const fetchData = async (s) => {
    try {
      const { data } = await axios.get(
        `${SERVER_URL}/user/rating?sort=${s}&offset=${offset}&limit=5`
      );

      setData((pre) => [...pre, ...data]);
    } catch (error) {}
  };

  React.useEffect(() => {
    fetchData(sort);
  }, [offset]);

  React.useEffect(() => {
    const handleScroll = (e) => {
      const scrollHeight = contentRef.current.scrollHeight;

      const currentHeigth = contentRef.current.scrollTop + window.innerHeight;

      if (currentHeigth + 1 >= scrollHeight) {
        setOffset(offset + 20);
      }
    };

    contentRef.current.addEventListener("scroll", handleScroll);

    return () => {
      contentRef.current?.removeEventListener("scroll", handleScroll);
    };
  }, [offset]);

  const changeRating = (sort) => {
    setSort(sort);
    setData([]);
    setOffset(0);
    fetchData(sort);
  };

  return (
    <Header title="Rating">
      <div className="sidebar">
        <ul className="item">
          <li
            onClick={() => changeRating("token")}
            className={sort == "token" ? "active" : ""}
          >
            <Icon i={"jewel2_blue"} size={32} />
            <div className="name">{__trans("Magical Gems")}</div>
          </li>
          <li
            onClick={() => changeRating("killMonsters")}
            className={sort == "killMonsters" ? "active" : ""}
          >
            <Icon i={"battle"} size={32} />

            <div className="name">{__trans("Monsters killed")}</div>
          </li>
        </ul>
      </div>
      <div ref={contentRef} className="b scrollnoshow">
        <div className="block">
          <div className="table_column table_complete">
            <div className="value">{stats.name}</div>
            <div className="value_last value_flex">
              {sort == "token" && (
                <>
                  {stats.token} <Icon i={"jewel2_blue"} size={24} />
                </>
              )}

              {sort == "killMonsters" && (
                <>
                  {stats.killMonsters}

                  <Icon i={"ticket"} size={24} />
                </>
              )}
            </div>
          </div>

          {data &&
            data.map((item, key) => (
              <div key={item._id} className="table_column table_default">
                <div className="value_sm value_center">
                  <Number num={key + 1} />
                </div>
                <div className="value">{item.name}</div>
                <div className="value_last value_flex">
                  {sort == "token" && (
                    <>
                      {item.token}

                      <Icon i={"jewel2_blue"} size={24} />
                    </>
                  )}

                  {sort == "killMonsters" && (
                    <>
                      {item.killMonsters}
                      <Icon i={"ticket"} size={24} />
                    </>
                  )}
                </div>
              </div>
            ))}
        </div>
      </div>
    </Header>
  );
};

export default Rating;
