import React from "react";
import styles from "./Guide.module.scss";
import Header from "../Header";
import monsters from "../../data/monsters";
import characters from "../../data/characters";
import maps from "../../data/maps";
import useTranslation from "../../utils/useTranslation";

const Guide = () => {
  const [__trans] = useTranslation();

  const [category, setCategory] = React.useState(1);

  const [selected, setSelected] = React.useState(false);

  const [data, setData] = React.useState(false);

  const getMonster = (selected) => {
    Object.entries(monsters).forEach(([type, value]) => {
      if (type === selected) {
        const newObj = { ...value, category: "monster" };

        setData(newObj);

        setSelected(type);
      }
    });
  };

  const getCharacter = (selected) => {
    Object.entries(characters).forEach(([type, value]) => {
      if (type === selected) {
        const newObj = { ...value, category: "character" };

        setData(newObj);

        setSelected(type);
      }
    });
  };

  const getMap = (selected) => {
    Object.entries(maps).forEach(([type, value]) => {
      if (type === selected) {
        const newObj = { ...value, category: "map" };

        setData(newObj);

        setSelected(type);
      }
    });
  };

  return (
    <Header title="Help">
      <div className="sidebar">
        {data && data.category == "monster" && (
          <>
            <div className={styles.frame}>
              <div className={styles.fr}>
                <img src={data.avatar} />
              </div>
              <div className={styles.title}>{data.type}</div>
              <div className={styles.sep}></div>

              <div className={styles.params}>
                <div className={styles.param}>
                  {__trans("Hit points")}
                  <div className={styles.value}>{data.maxHp}</div>
                </div>
                <div className={styles.param}>
                  {__trans("Melee damage")}
                  <div className={styles.value}>{data.damage}</div>
                </div>
                <div className={styles.param}>
                  {__trans("Reward")}
                  <div className={styles.value}>{data.coin}</div>
                </div>
              </div>
            </div>
          </>
        )}

        {data && data.category == "character" && (
          <>
            <div className={styles.frame}>
              <div className={styles.fr}>
                <img src={data.avatar} />
              </div>
              <div className={styles.title}>{data.name}</div>
              <div className={styles.sep}></div>

              <div className={styles.desc}></div>
            </div>
          </>
        )}

        {data && data.category == "map" && (
          <>
            <div className={styles.frame}>
              <div className={styles.fr}>
                <img src={data.avatar} />
              </div>
              <div className={styles.title}>{data.title}</div>
              <div className={styles.sep}></div>

              <div className={styles.desc}>{data.description}</div>
            </div>
          </>
        )}
      </div>

      <div className="block">
        <ul className="tabs">
          <li
            onClick={() => setCategory(1)}
            className={category == 1 && "active"}
          >
            {__trans("Monsters")}
          </li>
          <li
            onClick={() => setCategory(2)}
            className={category == 2 && "active"}
          >
            NPC
          </li>
          <li
            onClick={() => setCategory(3)}
            className={category == 3 && "active"}
          >
            {__trans("Locations")}
          </li>
        </ul>

        {category == 1 && (
          <div className={styles.grid}>
            {Object.entries(monsters).map(([key, value]) => {
              return (
                <div
                  onClick={() => getMonster(key)}
                  className={`${styles.fr} ${
                    selected === key ? styles.active : ""
                  }`}
                >
                  <img src={value.avatar} />
                </div>
              );
            })}
          </div>
        )}

        {category == 2 && (
          <div className={styles.grid}>
            {Object.entries(characters).map(([key, value]) => {
              return (
                <div
                  onClick={() => getCharacter(key)}
                  className={`${styles.fr} ${
                    selected === key ? styles.active : ""
                  }`}
                >
                  <img src={value.avatar} />
                </div>
              );
            })}
          </div>
        )}

        {category == 3 && (
          <div className={styles.grid}>
            {Object.entries(maps).map(([key, value]) => {
              return (
                <div
                  onClick={() => getMap(key)}
                  className={`${styles.fr} ${
                    selected === key ? styles.active : ""
                  }`}
                >
                  <img src={value.avatar} />
                </div>
              );
            })}
          </div>
        )}
      </div>
    </Header>
  );
};

export default Guide;
