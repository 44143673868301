import { SERVER_URL } from "../config/constants";

let tg;

try {
  tg = window.Telegram.WebApp;
} catch (e) {
  tg = { initData: 0 };
}

export function useTelegram() {
  const onClose = () => {
    tg.close();
  };

  const onToggleButton = () => {
    if (tg.MainButton.isVisible) {
      tg.MainButton.show();
    } else {
    }
  };

  let user = tg.initDataUnsafe?.user;

  if (SERVER_URL == "http://localhost:4444") {
    user = { id: 6645821049, first_name: "test", language_code: "ru" };
  }

  return {
    onClose,
    tg,
    user: user,
  };
}
