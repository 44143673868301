import stylesSeller from "./Seller.module.scss";
import spriteSeller from "./sprite/sellersprite.png";
import avatar from "./dialog.png";

const Seller = {
  type: "seller",
  name: "Peleas",
  description: "",
  styles: stylesSeller,
  sprite: spriteSeller,
  avatar: avatar,
  sprite: spriteSeller,
};

export default Seller;
