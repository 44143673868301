const monsters = [
  {
    type: "skeleton",
    position: [7, 13],
    direction: "right",
  },
  {
    type: "skeleton",
    position: [7, 12],
    direction: "right",
  },
  {
    type: "skeleton",
    position: [13, 24],
    direction: "down",
  },
  {
    type: "skeleton",
    position: [16, 26],
  },
  {
    type: "skeleton",
    position: [25, 27],
  },
  {
    type: "skeleton",
    position: [25, 22],
  },
  {
    type: "skeleton",
    position: [25, 23],
  },
  {
    type: "skeleton",
    position: [17, 18],
  },
  {
    type: "skeleton",
    position: [20, 15],
  },
  {
    type: "skeleton",
    position: [7, 7],
    direction: "down",
  },
  {
    type: "skeleton",
    position: [16, 9],
    direction: "down",
  },
  {
    type: "skeleton",
    position: [27, 14],
    direction: "right",
  },
  {
    type: "skeleton",
    position: [27, 15],
    direction: "right",
  },
  {
    type: "skeleton",
    position: [22, 9],
  },
  {
    type: "skeleton",
    position: [17, 21],
  },
  {
    type: "skeleton",
    position: [28, 9],
  },
  {
    type: "skeleton",
    position: [9, 28],
    direction: "up",
  },
  {
    type: "skeleton",
    position: [8, 28],
    direction: "up",
  },
  {
    type: "skeleton",
    position: [28, 28],
    direction: "up",
  },
  {
    type: "bat",
    position: [29, 28],
    direction: "up",
  },
  {
    type: "bat",
    position: [15, 14],
    direction: "right",
  },
  {
    type: "bat",
    position: [23, 17],
  },
  {
    type: "bat",
    position: [29, 21],
  },
  {
    type: "bat",
    position: [10, 18],
  },
  {
    type: "bat",
    position: [28, 8],
  },
  {
    type: "bat",
    position: [10, 19],
  },
  {
    type: "bat",
    position: [12, 24],
    direction: "down",
  },
  {
    type: "bat",
    position: [6, 7],
    direction: "down",
  },
  {
    type: "bat",
    position: [20, 28],
    direction: "up",
  },
  {
    type: "bat",
    position: [21, 28],
    direction: "up",
  },
];

export default monsters;
