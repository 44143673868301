import React from "react";
import styles from "./Intro.module.scss";
import { motion } from "framer-motion";

const Text = React.forwardRef(function Text(props, ref) {
  return (
    <div className={styles.dialog}>
      <motion.div ref={ref} initial={{ opacity: 0 }} className={styles.frame}>
        <div className={styles.text}>
          {props.text.split(" ").map((el, i) => (
            <motion.span initial={{ opacity: 0 }} key={i}>
              {el}
            </motion.span>
          ))}
        </div>
      </motion.div>
    </div>
  );
});

export default Text;
