import React from "react";
import { useSelector } from "react-redux";
import Stats from "../Stats";
import Messages from "../Messages";
import Map from "../Map";
import Player from "../Player";
import Spells from "../Spells";
import Controls from "../Controls";
import Dialogs from "../Dialogs";
import Minimap from "../Minimap";
import Dialog from "../Dialog";
import Hints from "../Hints";
import takeMonsters from "../Monsters/actions/takeMonsters";
import takeCharacters from "../Characters/actions/takeCharacters";
import isGamePaused from "../Dialogs/actions/is-game-paused";

const World = () => {
  const player = useSelector((state) => state.player);

  const intervalRef = React.useRef();

  React.useEffect(() => {
    const id = setInterval(() => {
      if (isGamePaused()) return;

      takeMonsters();
    }, 1000);

    intervalRef.current = id;

    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  // если было движение по сетке
  React.useEffect(() => {
    takeCharacters();
  }, [player.position]);

  return (
    <>
      <Minimap />
      <Stats />
      <Dialog />
      <Dialogs />
      <Messages />
      <Map>
        <Hints />
        <Player />
        <Spells />
      </Map>
      <Controls />
    </>
  );
};

export default World;
