import React from "react";
import { promiseAll } from "../actions/load-image";
import { useDispatch, useSelector } from "react-redux";
import { completePreloadMenu } from "../reducer";
import Loader from "../../../components/loader";
import playerData from "../../../data/player";
import Background from "../../../components/background";

const PreloadMenu = () => {
  const settings = useSelector((state) => state.settings);

  const dispatch = useDispatch();

  const complete = () => {
    dispatch(completePreloadMenu());
  };

  const INTRO = [
    "/assets/intro/1.jpg",
    "/assets/intro/2.jpg",
    "/assets/intro/3.jpg",
    "/assets/intro/4.jpg",
    "/assets/intro/5.jpg",
  ];

  React.useEffect(() => {
    let array = [];

    if (settings.firstLaunch) {
      let array = INTRO;
    }

    array.push(playerData.assasin.sprite);

    promiseAll(array, complete);
  }, []);

  return (
    <Background>
      <Loader />
    </Background>
  );
};

export default PreloadMenu;
