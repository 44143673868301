import React from "react";
import { store } from "../../../config/store";
import { addSpell } from "../reducer";

export const bloodMonster = (monsterPos) => {
  store.dispatch(
    addSpell({
      id: Math.random(1, 100),
      type: "blood",
      position: { x: monsterPos[0], y: monsterPos[1] },
      status: true,
      lifetime: 1,
    })
  );
};

export const healPlayerSpell = () => {
  const player = store.getState().player;

  store.dispatch(
    addSpell({
      id: Math.random(1, 100),
      type: "heal",
      position: { x: player.position[0], y: player.position[1] },
      status: true,
      lifetime: 1,
    })
  );
};

export const bloodPlayer = () => {
  const player = store.getState().player;

  store.dispatch(
    addSpell({
      id: Math.random(1, 100),
      type: "claw",
      position: { x: player.position[0], y: player.position[1] },
      status: true,
      lifetime: 1,
    })
  );
};
