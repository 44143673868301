import React from "react";
import styles from "./Token.module.scss";
import { useSelector } from "react-redux";
import Icon from "../../../components/icon";

const Token = () => {
  const stats = useSelector((state) => state.stats);

  return (
    <div className={styles.bar}>
      <Icon
        i={"jewel2_blue"}
        size={24}
        style={{ position: "absolute", zIndex: 999 }}
      />
      <div className={styles.icon}></div>
      <div className={styles.body}></div>
      <div className={styles.text}>{stats.token}</div>
    </div>
  );
};

export default Token;
