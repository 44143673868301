import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  gameOver: false,
  miniMap: false,
};

export const dialogsSlice = createSlice({
  name: "dialogs",
  initialState,
  reducers: {
    died(state, action) {
      state.gameOver = action.payload;
    },
    openMiniMap(state) {
      state.miniMap = true;
    },
    closeMiniMap(state) {
      state.miniMap = false;
    },
  },
});

export const { died, openMiniMap, closeMiniMap } = dialogsSlice.actions;

export default dialogsSlice.reducer;
