import React from "react";
import styles from "./Background.module.scss";

const Background = ({ onClick, children }) => {
  function handleClick() {
    if (typeof onClick === "function") {
      onClick();
    }
  }

  return (
    <div onClick={handleClick} className={styles.bg}>
      {children}
    </div>
  );
};

export default Background;
