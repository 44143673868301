import React from "react";
import styles from "./Intro.module.scss";
import { useAnimate } from "framer-motion";
import { stagger, motion } from "framer-motion";
import Text from "./Text";
import { useDispatch } from "react-redux";
import { completeFirstLaunch } from "../../config/slices/settings";
import useTranslation from "../../utils/useTranslation";

const Intro = () => {
  const [__trans] = useTranslation();

  const dispatch = useDispatch();

  const [scope, animate] = useAnimate();

  const backgroundRef = React.useRef();

  const text1Ref = React.useRef();

  const text2Ref = React.useRef();

  const text3Ref = React.useRef();

  const text4Ref = React.useRef();

  const text5Ref = React.useRef();

  const animateText = (ref) => {
    return [
      [ref, { display: "block" }],
      [ref, { opacity: 1 }],

      [
        ref?.querySelectorAll("span"),
        { opacity: 1 },
        { duration: 2, delay: stagger(0.2) },
      ],

      [ref, { opacity: 0 }, { duration: 1 }],
      [ref, { display: "none" }],
    ];
  };

  React.useEffect(() => {
    const enterAnimation = async () => {
      // 1 слайд
      await animate(backgroundRef.current, {
        backgroundImage: "url('/assets/intro/1.jpg')",
      });

      await animate(animateText(text1Ref.current));

      // 2 слайд
      await animate(backgroundRef.current, {
        backgroundImage: "url('/assets/intro/2.jpg')",
      });

      await animate(animateText(text2Ref.current));

      // 3 слайд
      await animate(backgroundRef.current, {
        backgroundImage: "url('/assets/intro/3.jpg')",
      });

      await animate(animateText(text3Ref.current));

      // 4 слайд
      await animate(backgroundRef.current, {
        backgroundImage: "url('/assets/intro/4.jpg')",
      });

      await animate(animateText(text4Ref.current));

      // 5 слайд
      await animate(backgroundRef.current, {
        backgroundImage: "url('/assets/intro/5.jpg')",
      });

      await animate(animateText(text5Ref.current));

      skipIntro();
    };
    enterAnimation();
  });

  const skipIntro = () => {
    dispatch(completeFirstLaunch());
  };

  const text = [
    __trans(
      "The Kingdom of Edenlia has been a powerful and prosperous kingdom for 6,000 years."
    ),
    __trans(
      "Abundant lands with dense forests, fertile fields and huge deposits of magical gems."
    ),
    __trans(
      "Because of the magic created from magic gems, the king's army did not know defeats."
    ),
    __trans(
      "When the supply of magic gems began to run out, hordes of merciless demons, undead rallied into a Dark Legion and began to besiege the kingdom of Edenlia."
    ),
    __trans("And only the capital of Viula has survived..."),
  ];

  return (
    <motion.div
      onClick={() => skipIntro()}
      ref={backgroundRef}
      className={styles.pic}
    >
      <Text ref={text1Ref} text={text[0]} />
      <Text ref={text2Ref} text={text[1]} />
      <Text ref={text3Ref} text={text[2]} />
      <Text ref={text4Ref} text={text[3]} />
      <Text ref={text5Ref} text={text[4]} />
    </motion.div>
  );
};

export default Intro;
