import React from "react";
import { useDispatch } from "react-redux";
import {
  changePxPosition,
  setPlayerAttacked,
  setPlayerMoved,
} from "../Player/reducer";
import movePlayer from "../Player/actions/movePlayer";
import styles from "./Controls.module.scss";
import attackMonster from "../Player/actions/attackMonster";
import isGamePaused from "../Dialogs/actions/is-game-paused";
import { connect } from "react-redux";
const Controls = () => {
  const dispatch = useDispatch();

  const upButtonRef = React.useRef();
  const downButtonRef = React.useRef();
  const leftButtonRef = React.useRef();
  const rightButtonRef = React.useRef();

  const attackButtonRef = React.useRef();

  const pressedRef = React.useRef(false);

  const requestRef = React.useRef(false);

  const timerRef = React.useRef(0);
  const animateRef = React.useRef(false);

  const controlRef = React.useRef(true); // если true то можем нажимать

  const animateMove = (timestamp, direction) => {
    if (timerRef.current >= 60) {
      controlRef.current = true;

      if (!pressedRef.current) {
        dispatch(setPlayerMoved(false));
      }

      cancelAnimationFrame(animateRef.current);
    } else {
      animateRef.current = requestAnimationFrame(function (timestamp) {
        animateMove(timestamp, direction);
      });

      dispatch(changePxPosition(direction));

      timerRef.current += 1;
    }
  };

  const touchCancel = () => {
    pressedRef.current = false;
  };

  const animate = () => {
    requestRef.current = requestAnimationFrame(animate);

    if (!pressedRef.current) {
      cancelAnimationFrame(requestRef.current);
    }

    if (controlRef && controlRef.current) {
      if (pressedRef.current == "up") {
        move("up");
      }
      if (pressedRef.current == "down") {
        move("down");
      }
      if (pressedRef.current == "left") {
        move("left");
      }
      if (pressedRef.current == "right") {
        move("right");
      }
    }
  };

  const touchButtons = function (button) {
    return function (event) {
      switch (button) {
        case "up":
          pressedRef.current = "up";
          requestRef.current = requestAnimationFrame(animate);
          break;
        case "down":
          pressedRef.current = "down";
          requestRef.current = requestAnimationFrame(animate);
          break;
        case "left":
          pressedRef.current = "left";
          requestRef.current = requestAnimationFrame(animate);
          break;
        case "right":
          pressedRef.current = "right";
          requestRef.current = requestAnimationFrame(animate);
          break;
      }
    };
  };

  React.useEffect(() => {
    attackButtonRef.current.addEventListener("click", attackButton);

    window.addEventListener("keydown", handleKeyDown);

    upButtonRef.current.addEventListener("touchstart", touchButtons("up"));
    upButtonRef.current.addEventListener("touchend", touchCancel);

    downButtonRef.current.addEventListener("touchstart", touchButtons("down"));
    downButtonRef.current.addEventListener("touchend", touchCancel);

    leftButtonRef.current.addEventListener("touchstart", touchButtons("left"));
    leftButtonRef.current.addEventListener("touchend", touchCancel);

    rightButtonRef.current.addEventListener(
      "touchstart",
      touchButtons("right")
    );

    rightButtonRef.current.addEventListener("touchend", touchCancel);

    return () => {
      pressedRef.current = false;
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const attackButton = () => {
    if (isGamePaused()) return;

    const check = attackButtonRef.current.className.includes(styles.wait);

    if (controlRef.current == false || check) {
      return false;
    }

    attackButtonRef.current.classList.add(styles.wait);

    dispatch(setPlayerAttacked(true));

    setTimeout(() => {
      dispatch(setPlayerAttacked(false));
      attackButtonRef?.current?.classList.remove(styles.wait);
    }, 1000);

    attackMonster();
  };

  const move = (direction) => {
    if (isGamePaused()) return;

    let walk = false;

    switch (direction) {
      case "up":
        walk = movePlayer("up");
        break;
      case "right":
        walk = movePlayer("right");
        break;
      case "down":
        walk = movePlayer("down");
        break;
      case "left":
        walk = movePlayer("left");
        break;
    }

    if (walk == false) {
      // не удалось выполнить движение
      dispatch(setPlayerMoved(false));
      touchCancel();
      return false;
    }

    controlRef.current = false;
    dispatch(setPlayerMoved(true));
    timerRef.current = 0;

    animateRef.current = requestAnimationFrame(function (timestamp) {
      animateMove(timestamp, direction);
    });
  };

  const handleKeyDown = React.useCallback((e) => {
    if (controlRef.current == false) {
      return false;
    }

    switch (e.code) {
      case "KeyA":
      case "ArrowLeft":
        move("left");
        break;
      case "KeyW":
      case "ArrowUp":
        move("up");
        break;
      case "KeyD":
      case "ArrowRight":
        move("right");
        break;
      case "KeyS":
      case "ArrowDown":
        move("down");
        break;
    }
  }, []);

  return (
    <>
      <div className={styles.wrap}>
        <div ref={upButtonRef} className={styles.quart}></div>
        <div ref={rightButtonRef} className={styles.quart}></div>
        <div ref={leftButtonRef} className={styles.quart}></div>
        <div ref={downButtonRef} className={styles.quart}></div>
      </div>
      <div ref={attackButtonRef} className={styles.attack}></div>
    </>
  );
};

//export default Controls;

const actions = { isGamePaused };

export default connect(null, actions)(Controls);
