import React from "react";
import styles from "./Language.module.scss";
import { useDispatch, useSelector } from "react-redux";
import Language from "../Language";
import Button from "../../components/button";
import { changePopup } from "../../config/slices/menu";

const LanguageButton = () => {
  const dispatch = useDispatch();

  const settings = useSelector((state) => state.settings);

  const menu = useSelector((state) => state.menu);

  const openPopup = () => {
    dispatch(changePopup(true));
  };

  return (
    <>
      {menu.popup && <Language />}
      <Button onClick={() => openPopup()} color="gray">
        {settings.language == "en" && (
          <>
            <div className={styles.icon}>
              <div className={`${styles.icon} ${styles.en}`}></div>
            </div>
            English
          </>
        )}

        {settings.language == "ru" && (
          <>
            <div className={styles.icon}>
              <div className={`${styles.icon} ${styles.ru}`}></div>
            </div>
            Русский
          </>
        )}
      </Button>
    </>
  );
};

export default LanguageButton;
