import React from "react";
import { store } from "../../../config/store";
import { addCharacters } from "../reducer";
import maps from "../../../data/maps";

export default function loadCharacters() {
  const characters = store.getState().characters;

  const { map } = store.getState().player;

  if (!characters.components[map]) {
    if (maps[map].characters) {
      store.dispatch(
        addCharacters({
          characters: maps[map].characters,
          map: map,
        })
      );
    }
  }
}
