import React from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import styles from "./Partners.module.scss";

import InviteLink from "../../../components/invite-link";
import { dateFormat } from "../../../utils/date-format";
import useTranslation from "../../../utils/useTranslation";
import { SERVER_URL } from "../../../config/constants";
import Icon from "../../../components/icon";
import { getMultiplier } from "../../../features/Stats/reducer";

const Partners = ({ full }) => {
  const [__trans] = useTranslation();

  const [data, setData] = React.useState([]);
  const [offset, setOffset] = React.useState(0);

  const stats = useSelector((state) => state.stats);

  const multiplier = useSelector(getMultiplier);

  const id = useSelector((state) => state.stats._id);

  const contentRef = React.useRef(false);

  const fetchData = async () => {
    try {
      const { data } = await axios.get(
        `${SERVER_URL}/partner/get?id=${id}&offset=${offset}&limit=5`
      );

      setData((pre) => [...pre, ...data]);
    } catch (error) {}
  };

  React.useEffect(() => {
    fetchData();
  }, [offset]);

  React.useEffect(() => {
    const handleScroll = (e) => {
      const scrollHeight = contentRef.current.scrollHeight;

      const currentHeigth = contentRef.current.scrollTop + window.innerHeight;

      if (currentHeigth + 1 >= scrollHeight) {
        setOffset(offset + 20);
      }
    };

    contentRef.current.addEventListener("scroll", handleScroll);

    return () => {
      contentRef.current?.removeEventListener("scroll", handleScroll);
    };
  }, [offset]);

  return (
    <div ref={contentRef} className="scrollnoshow">
      <div className={styles.balance}>
        * {multiplier}
        <Icon i={"coin"} size={64} />
      </div>
      <div style={{ color: "#999", fontSize: "12px", marginBottom: "5px" }}>
        Множитель монет, зависит от количества лично приглашенных партнеров. За
        монеты покупаются Magic Gems Token (MGT)
      </div>

      {full && (
        <>
          <div className={styles.balance}>
            {stats.balance}
            <div className={styles.icon_usd}></div>
          </div>
          <div className="table">
            <div className="table_header">
              <div className="value">{__trans("Level")} 1</div>
              <div className="value">{__trans("Level")} 2</div>
              <div className="value">{__trans("Level")} 3</div>
              <div className="value">{__trans("Level")} 4</div>
            </div>
            <div className="table_column table_default">
              <div className="value">7%</div>
              <div className="value">5%</div>
              <div className="value">4%</div>
              <div className="value">2%</div>
            </div>
          </div>
        </>
      )}

      <InviteLink />

      <div className="table">
        <div className="table_header">
          <div className="value">{__trans("Date")}</div>
          <div className="value">{__trans("Username")}</div>
          <div className="value">{__trans("Level")}</div>
          {/* <div className="value_last">{__trans("Tokens purchased")}</div> */}
        </div>

        {data &&
          data.map((item, key) => (
            <div key={item._id} className="table_column table_default">
              <div className="value">{dateFormat(item.createdAt)}</div>
              <div className="value">{item.partnerId.name}</div>
              <div className="value">{item.level}</div>
              {/* <div className="value_last">{item.partnerId.buyingToken}</div> */}
            </div>
          ))}
      </div>
    </div>
  );
};

export default Partners;
