import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { SERVER_URL } from "../constants";
import { store } from "../store";

export const fetchInviting = createAsyncThunk("get/bonuses", async (user) => {
  try {
    const id = store.getState().stats._id;

    const response = await axios.post(`${SERVER_URL}/partner/today`, {
      id,
    });

    return response.data;
  } catch (error) {}
});

const initialState = {
  daily_reset: false,

  todayInviting: 0,
  totalInviting: 0,
  todayKillMonsters: 0,
  totalKillMonsters: 0,

  daily: [
    {
      type: "inviting",
      need: 1,
      complete: false,
      reward: 1000,
      reward_type: "gold",
    },
    {
      type: "inviting",

      need: 5,
      complete: false,
      reward: 1,
      reward_type: "token",
    },
    {
      type: "inviting",
      need: 15,
      complete: false,
      reward: 2,
      reward_type: "token",
    },

    {
      type: "killing",
      need: 10,
      complete: false,
      reward: 1000,
      reward_type: "gold",
    },
    {
      type: "killing",
      need: 50,
      complete: false,
      reward: 1,
      reward_type: "token",
    },
  ],

  // inviting: {
  //   type: "inviting",
  //   total: 0,
  //   need: 5,
  //   complete: false,
  //   reward: 5000,
  //   reward_type: "gold",
  //   daily: true,
  // },

  // killing: {
  //   type: "killing",
  //   total: 20,
  //   need: 20,
  //   complete: false,
  //   reward: 1000,
  //   reward_type: "gold",
  //   daily: true,
  // },

  // rating_gold: {
  //   type: "rating_gold",
  //   total: 0,
  //   need: 11,
  //   complete: false,
  //   reward: 5000,
  //   reward_type: "gold",
  //   daily: false,
  // },
};

export const bonusSlice = createSlice({
  name: "bonus",
  initialState,
  reducers: {
    dailyReset(state, action) {
      const today = new Date().toDateString();

      if (state.daily_reset != today) {
        state.todayKillMonsters = 0;

        state.daily_reset = today;
      }
    },

    increase(state, action) {
      state.todayKillMonsters += 1;
    },

    completeBonus(state, action) {
      state.daily[action.payload.index].complete = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchInviting.fulfilled, (state, action) => {
      state.todayInviting = action.payload.count;
      state.status = "success";
    });
  },
});

export const { dailyReset, increase, completeBonus } = bonusSlice.actions;

export default bonusSlice.reducer;
