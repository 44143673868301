import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  height: false,
};

export const viewportSlice = createSlice({
  name: "spells",
  initialState,
  reducers: {
    changeHeight(state, action) {
      state.height = action.payload;
    },
  },
});

export const { changeHeight } = viewportSlice.actions;

export default viewportSlice.reducer;
