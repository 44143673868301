import { store } from "../../../config/store";
import loadCharacters from "../../Characters/actions/loadCharacters";
import loadMonsters from "../../Monsters/actions/loadMonsters";
import {
  changePixelPosition,
  changePosition,
  setMap,
} from "../../Player/reducer";
import { setPreloadMap } from "../../Preload/reducer";

const changeMap = (map, rebithPlayer = false) => {
  // меняем карту в map slices

  if (rebithPlayer) {
    store.dispatch(setMap(1));

    store.dispatch(changePosition(rebithPlayer));

    store.dispatch(
      changePixelPosition([rebithPlayer[0] * 60, rebithPlayer[1] * 60])
    );
  } else {
    store.dispatch(setMap(map.map));

    store.dispatch(changePosition(map.position));

    store.dispatch(
      changePixelPosition([map.position[0] * 60, map.position[1] * 60])
    );
  }

  // меняем позицию героя
  // добавляем карту в предзагрузку

  loadMonsters(); // загрузка монстров

  loadCharacters(); // загрузка персонажей

  store.dispatch(setPreloadMap());
};

export default changeMap;
