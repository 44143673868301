import React from "react";
import styles from "./Health.module.scss";
import { useSelector } from "react-redux";

const Health = () => {
  const stats = useSelector((state) => state.stats);

  const progressRef = React.useRef();

  const setWidth = (width) => {
    progressRef.current.style.background =
      "linear-gradient(to right, #1e8edc 0%, #1e8edc " +
      width +
      "%, #000 " +
      width +
      "%, #000 100%)";
  };

  React.useEffect(() => {
    const width = (stats.hp / stats.maxHp) * 100;

    if (width > 100) {
      setWidth(100);
    } else {
      setWidth(width);
    }
  }, [stats]);

  return (
    <div className={styles.health}>
      <div ref={progressRef} className={styles.body}>
        <div className={styles.info}>
          {stats.hp} / {stats.maxHp}
        </div>
      </div>
    </div>
  );
};

export default Health;
