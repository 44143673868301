import React from "react";
import styles from "./InviteLink.module.scss";
import { useSelector } from "react-redux";
import Input from "../input";
import Button from "../button";
import { selectInviteLink } from "../../features/Stats/reducer";
import useCopyToClipboard from "../../utils/useCopyToClipboard";

const InviteLink = () => {
  const { isCopied, copyToClipboard } = useCopyToClipboard();
  const inviteLink = useSelector(selectInviteLink);

  return (
    <div className="table_column table_default">
      <div className={styles.invite}>
        <Input value={inviteLink} />
        <Button onClick={() => copyToClipboard(inviteLink)} color={"blue"}>
          {isCopied ? "Copied!" : "Copy to Clipboard"}
        </Button>
      </div>
    </div>
  );
};

export default InviteLink;
