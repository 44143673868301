import React from "react";
import styles from "./Heroes.module.scss";
import Header from "../Header";
import { useSelector } from "react-redux";
import useTranslation from "../../utils/useTranslation";

const Heroes = () => {
  const [__trans] = useTranslation();

  const stats = useSelector((state) => state.stats);

  return (
    <Header title="Heroes">
      <div className={styles.sel}>
        <div className={styles.hero}> </div>
        <div className={styles.bottom}> </div>
      </div>

      <div className={styles.data}>
        <div className={styles.info}>
          <div className={styles.icon}>
            <div className={styles.assasin}></div>
          </div>
          <div className={styles.title}>
            <div className={styles.name}>Username</div>
            <div className={styles.special}>Assasin</div>
          </div>
        </div>

        <div className={styles.descr}></div>

        <div className={styles.level}>{stats.level}</div>

        <div className={styles.params}>
          <div className={` ${styles.param} ${styles.bir}`}>
            <div className={` ${styles.name} `}>
              <div className={styles.icon_health}></div>
              {__trans("Hit points")}
            </div>
            <div className={styles.value}>{stats.maxHp}</div>
          </div>
          <div className={` ${styles.param} ${styles.red}`}>
            <div className={` ${styles.name} `}>
              <div className={styles.icon_damage}></div>
              {__trans("Melee damage")}
            </div>
            <div className={styles.value}>{stats.damage}</div>
          </div>
        </div>
      </div>
    </Header>
  );
};

export default Heroes;
