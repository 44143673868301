import React from "react";
import styles from "./Continue.module.scss";
import Background from "../../components/background";
import useTranslation from "../../utils/useTranslation";

const Continue = ({ onClick }) => {
  const [__trans] = useTranslation();

  function handleClick() {
    if (typeof onClick === "function") {
      onClick();
    }
  }

  return (
    <Background onClick={handleClick}>
      <div className={styles.inner}>
        <div className={styles.logo}></div>
        <div className={styles.start}>{__trans("Tap to start")}</div>
      </div>
    </Background>
  );
};

export default Continue;
