import React from "react";
import { store } from "../../../config/store";
import { radiusAttack } from "../../../utils/monster-attack-radius";

import { changeDirection } from "../reducer";
import { openDialog } from "../../Dialog/actions/dialog-manager";

function playerInRange(playerPos, monsterPos) {
  let inRange = false;

  // for each tile around the monster
  radiusAttack(1).forEach((tile) => {
    // // add the monsters location
    const offsetX = tile.x + monsterPos[0];
    const offsetY = tile.y + monsterPos[1];

    if (JSON.stringify([offsetX, offsetY]) === JSON.stringify(playerPos)) {
      inRange = true;
    }
  });
  return inRange;
}

const takeCharacters = () => {
  const characters = store.getState().characters;

  const player = store.getState().player;

  const currentMap = player.map;

  if (!characters.components[currentMap]) {
    return;
  }

  Object.entries(characters.components[currentMap]).forEach(
    ([characterId, value]) => {
      const { position } = value;

      if (playerInRange(player.position, value.position)) {
        if (value.type == "healer") {
          openDialog("healer");
        }
        if (value.type == "seller") {
          openDialog("seller");
        }
        if (value.type == "knight") {
          openDialog("knight");
        }

        // get distance from player on both axis
        const xDiff = position[0] - player.position[0];
        const yDiff = position[1] - player.position[1];
        const greaterY = Math.abs(yDiff) > Math.abs(xDiff);

        // see if y axis is greater distance from player
        if (greaterY) {
          // if the monster is mostly below the player on the y axis
          if (yDiff > 0) {
            // move the monster 'up' relatively

            store.dispatch(
              changeDirection({
                map: currentMap,
                id: characterId,
                direction: "up",
              })
            );
          }
          // if the monster is mostly above the player on the y axis
          else if (yDiff < 0) {
            // move the monster 'down' relatively

            store.dispatch(
              changeDirection({
                map: currentMap,
                id: characterId,
                direction: "down",
              })
            );
          }
        } // x axis is greater distance from player
        else {
          // if the monster is mostly to the right of the player
          if (xDiff > 0) {
            // move the monster 'left' relatively

            store.dispatch(
              changeDirection({
                map: currentMap,
                id: characterId,
                direction: "left",
              })
            );
          }
          // if the monster is mostly to the left of the player
          else if (xDiff < 0) {
            // move the monster 'right' relatively

            store.dispatch(
              changeDirection({
                map: currentMap,
                id: characterId,
                direction: "right",
              })
            );
          }
        }
      }
    }
  );
};

export default takeCharacters;
