import map from "./assets/map.jpg";

import miniMap from "./assets/minimap.jpg";

import preloadImage from "./assets/preload.jpg";

import tiles from "./tiles";

import characters from "./characters";

import monsters from "./monsters";

import music from "./assets/music_forest.mp3";

import avatar from "./assets/avatar.png";

const title = "Ominous Forest";

const description = "";

const mapHeight = 3840;

const mapWidth = 3840;

const entryPoints = {
  7: { map: 1, position: [56, 45] }, // вход в королество
};

export default {
  map,
  miniMap,
  title,
  description,
  tiles,
  monsters,
  characters,
  mapHeight,
  mapWidth,
  preloadImage,
  entryPoints,
  music,
  avatar,
};
