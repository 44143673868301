import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  menu: true, // загрузка меню
  map: false, // загрузка карты с монстрами
};

export const preloadSlice = createSlice({
  name: "preload",
  initialState,
  reducers: {
    setPreloadMenu(state) {
      state.menu = true;
    },
    completePreloadMenu(state) {
      state.menu = false;
    },

    setPreloadMap(state) {
      state.map = true;
    },
    completePreloadMap(state) {
      state.map = false;
    },
  },
});

export const {
  setPreloadMenu,
  completePreloadMenu,
  setPreloadMap,
  completePreloadMap,
} = preloadSlice.actions;

export default preloadSlice.reducer;
