import React from "react";
import styles from "./Frame.module.scss";

const CharacterFrame = ({ title, avatar }) => {
  return (
    <div className={styles.a}>
      <div className={styles.lab}>{title}</div>
      <div className={styles.frame}>
        <div
          style={{
            backgroundImage: `url(${avatar})`,
          }}
          className={styles.ava}
        ></div>
      </div>
    </div>
  );
};

export default CharacterFrame;
