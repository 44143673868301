import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Active from "./active";
import Wait from "./wait";
import Inviting from "./inviting";
import Killing from "./killing";
import Complete from "./complete";
import { completeBonus } from "../../../config/slices/bonus";

const Bonus = ({ need, complete, type, reward, reward_type, index }) => {
  const dispatch = useDispatch();

  const bonus = useSelector((state) => state.bonus);

  const getTotal = () => {
    if (type == "inviting") {
      return bonus.todayInviting;
    }
    if (type == "killing") {
      return bonus.todayKillMonsters;
    }
  };

  const total = getTotal();

  const receive = () => {
    if (total >= need && complete === false) {
      if (reward_type === "gold") {
        // dispatch(
        //   updateTokens({
        //     value: "coin",
        //     count: reward,
        //   })
        // );
      }

      if (reward_type === "token") {
        dispatch();
        // updateTokens({
        //   value: "token",
        //   count: reward,
        // })
      }

      dispatch(completeBonus({ index }));
    }
  };

  return (
    <div
      className={
        complete ? "table_column table_complete" : "table_column table_default"
      }
    >
      {type == "inviting" && <Inviting />}
      {type == "killing" && <Killing />}

      {complete ? (
        <Complete reward={reward} reward_type={reward_type} />
      ) : (
        <>
          {total >= need ? (
            <Wait
              reward={reward}
              reward_type={reward_type}
              onClick={() => receive()}
            />
          ) : (
            <Active
              total={total}
              need={need}
              reward={reward}
              reward_type={reward_type}
            />
          )}
        </>
      )}
    </div>
  );
};

export default Bonus;
