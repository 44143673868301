import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
};

export const messagesSlice = createSlice({
  name: "messages",
  initialState,
  reducers: {
    removeMessage(state, action) {
      state.data = state.data.filter((obj) => obj.id != action.payload);
    },
    addMessage(state, action) {
      state.data.push(action.payload);
    },
  },
});

export const { removeMessage, addMessage } = messagesSlice.actions;

export default messagesSlice.reducer;
