import React from "react";
import styles from "./Preload.module.scss";
import { promiseAll } from "../actions/load-image";
import { useDispatch, useSelector } from "react-redux";
import { completePreloadMap } from "../reducer";
import Loader from "../../../components/loader";
import maps from "../../../data/maps";
import preloadPack from "../actions/preload-pack";
import Label from "../../Map/Label";
import Background from "../../../components/background";

const PreloadMap = () => {
  const currentMap = useSelector((state) => state.player.map);

  const dispatch = useDispatch();

  const dataCurrentMap = maps[currentMap];

  const complete = () => {
    dispatch(completePreloadMap());
  };

  React.useEffect(() => {
    const preloadImages = preloadPack(currentMap);
    promiseAll(preloadImages, complete);
  }, []);

  return (
    <Background>
      <Label>{dataCurrentMap.title}</Label>
      <Loader />
    </Background>
  );
};

export default PreloadMap;
