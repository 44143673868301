import React from "react";
import { store } from "../../../config/store";
import { addMonsters } from "../reducer";
import maps from "../../../data/maps";

export default function loadMonsters() {
  const currentMap = store.getState().player.map;

  store.dispatch(
    addMonsters({
      monsters: maps[currentMap].monsters,
      map: currentMap,
    })
  );
}
