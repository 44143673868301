import maps from "../../../data/maps";
import monsters from "../../../data/monsters";
import characters from "../../../data/characters";

const preloadPack = (map) => {
  let array = [];

  const dataCurrentMap = maps[map];

  if (dataCurrentMap?.monsters) {
    Object.entries(dataCurrentMap.monsters).forEach(([key, value]) => {
      array.push(monsters[value.type].sprite);
    });
  }

  if (dataCurrentMap?.characters) {
    Object.entries(dataCurrentMap.characters).forEach(([key, value]) => {
      array.push(characters[value.type].sprite);
    });
  }

  array.push(dataCurrentMap.map);
  array.push(dataCurrentMap.miniMap);

  return array;
};

export default preloadPack;
