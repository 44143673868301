import { changePaused } from "../../../config/slices/menu";
import { store } from "../../../config/store";
import { changeOpenState } from "../reducer";

export const openDialog = (type) => {
  store.dispatch(changeOpenState({ type, open: true }));

  // на паузу ставим
  store.dispatch(changePaused(true));
};

export const closeDialog = () => {
  store.dispatch(changeOpenState({ open: false }));

  // на паузу ставим
  store.dispatch(changePaused(false));
};
