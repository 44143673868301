import React from "react";
import Start from "./Start";
import Mobile from "./features/Mobile";
import { isMobile, touchCheck } from "./utils/mobile";

function App() {
  // if (!isMobile() || !touchCheck()) {
  //   //return <Mobile />;
  // }

  return <Start />;
}

export default App;
