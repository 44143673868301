import React from "react";
import styles from "./Dialog.module.scss";
import Healer from "./healer";
import { useSelector } from "react-redux";
import Seller from "./seller";
import Knight from "./knight";

const Dialog = () => {
  const dialog = useSelector((state) => state.dialog);

  if (!dialog.open) {
    return <></>;
  }

  let type = false;

  switch (dialog.type) {
    case "healer":
      type = <Healer />;
      break;
    case "seller":
      type = <Seller />;
      break;
    case "knight":
      type = <Knight />;
      break;
  }

  if (!type) {
    return false;
  }

  return (
    <div className={styles.dialog}>
      <div className={styles.frame}>
        <div className={styles.body}>{type}</div>
      </div>
    </div>
  );
};

export default Dialog;
