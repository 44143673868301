import React from "react";
import { useSelector } from "react-redux";
import styles from "../Minimap.module.scss";

const Enemies = () => {
  const monsters = useSelector((state) => state.monsters);

  const { map } = useSelector((state) => state.player);

  return (
    <>
      {Object.entries(monsters.components[map]).map(([key, value]) => {
        return (
          <div
            key={key}
            style={{
              left: value.position[0] * 6,
              top: value.position[1] * 6,
            }}
            className={styles.enemy}
          ></div>
        );
      })}
    </>
  );
};

export default Enemies;
