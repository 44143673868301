import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./Map.module.scss";
import useWindowSize from "./actions/useWindowSize";
import Monsters from "../Monsters";
import Characters from "../Characters";
import maps from "../../data/maps";
import { changePixelPosition } from "../Player/reducer";

const Map = ({ children }) => {
  const dispatch = useDispatch();

  const { width, height } = useWindowSize();

  const rootRef = React.useRef();

  const mapRef = React.useRef();

  const player = useSelector((state) => state.player);

  const dataCurrentMap = maps[player.map];

  React.useEffect(() => {
    dispatch(
      changePixelPosition([player.position[0] * 60, player.position[1] * 60])
    );
  }, []);

  React.useEffect(() => {
    const worldTop = rootRef.current.clientWidth / 2 - player.px[0] - 30;
    const worldLeft = rootRef.current.clientHeight / 2 - player.px[1] - 30;

    mapRef.current.style.transform = `translate3d( ${worldTop}px, ${worldLeft}px, 0 )`;
  }, [width, height, player.px]);

  return (
    <>
      <div ref={rootRef} className={styles.root}>
        <div
          style={{
            backgroundImage: `url(${dataCurrentMap.map})`,
            width: `${dataCurrentMap.mapWidth}px`,
            height: `${dataCurrentMap.mapHeight}px`,
          }}
          ref={mapRef}
          className={styles.map}
        >
          {children}
          <Monsters />
          <Characters />
        </div>
      </div>
    </>
  );
};

export default Map;
