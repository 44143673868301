import React from "react";
import styles from "./Partner.module.scss";
import Header from "../Header";
import Partners from "./Partners";
import useTranslation from "../../utils/useTranslation";

const Partner = () => {
  const [__trans] = useTranslation();

  return (
    <Header title="Affiliate Program">
      <div className={styles.block}>
        <Partners full={false} />
      </div>
    </Header>
  );
};

export default Partner;
