import React from "react";
import Button from "../../../components/button";
import Reward from "./Reward";

const Wait = ({ onClick, reward_type, reward }) => {
  function handleClick() {
    if (typeof onClick === "function") {
      onClick();
    }
  }

  return (
    <>
      <div className="value_last value_center value_column">
        <Reward reward={reward} type={reward_type} />
      </div>
      <div className="value_last">
        <Button onClick={handleClick} color={"green"}>
          Получить
        </Button>
      </div>
    </>
  );
};

export default Wait;
