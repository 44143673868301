import { store } from "../../../config/store";
import { addMessage } from "../reducer";

export const errorMessage = (message) => {
  store.dispatch(
    addMessage({
      id: Math.random(1, 100),
      text: message ?? "ошибка",
      type: "error",
      lifetime: 2,
    })
  );
};

export const infoMessage = (message) => {
  store.dispatch(
    addMessage({
      id: Math.random(1, 100),
      text: message ?? "ошибка",
      type: "info",
      lifetime: 2,
    })
  );
};
