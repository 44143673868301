import map from "./assets/map.jpg";

import miniMap from "./assets/minimap.jpg";

import preloadImage from "./assets/preload.jpg";

import tiles from "./tiles";

import characters from "./characters";

import monsters from "./monsters";

import music from "./assets/music_forest.mp3";

import avatar from "./assets/avatar.jpg";

const title = "Dungeon";

const description = "";

const mapHeight = 2280;

const mapWidth = 2280;

const entryPoints = {
  2: { map: 1, position: [53, 10] },
};

export default {
  map,
  miniMap,
  title,
  description,
  tiles,
  monsters,
  characters,
  mapHeight,
  mapWidth,
  preloadImage,
  entryPoints,
  music,
  avatar,
};
