import React from "react";
import styles from "./Header.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { changePage } from "../../config/slices/menu";
import useTranslation from "../../utils/useTranslation";

const Header = ({ title, children }) => {
  const [__trans] = useTranslation();

  const dispatch = useDispatch();

  const viewport = useSelector((state) => state.viewport);

  const contentRef = React.useRef(false);

  React.useEffect(() => {
    if (contentRef.current) {
      contentRef.current.style.height = viewport.height - 125 + "px";
    }
  }, [viewport]);

  const home = () => {
    dispatch(changePage("home"));
  };

  return (
    <>
      <div className="background">
        <div className={styles.header}>
          <div className={styles.inner}>
            <div onClick={() => home()} className={styles.prev}></div>
            <div className={styles.title}>{__trans(title)}</div>
            <div onClick={() => home()} className={styles.home}></div>
          </div>

          <div className={styles.hr}></div>
        </div>
        <div ref={contentRef} className="content scrollnoshow">
          {children}
        </div>
      </div>
    </>
  );
};

export default Header;
