import React from "react";
import styles from "./Answer.module.scss";

const Answer = ({ children, onClick, price, icon }) => {
  function handleClick() {
    if (typeof onClick === "function") {
      onClick();
    }
  }

  return (
    <>
      <div onClick={handleClick} className={styles.info}>
        <div className={styles.top}></div>
        <div className={styles.body}>
          <div className={styles.text}>
            {children}

            {price && (
              <div className={styles.price}>
                <div className={styles.gold}></div>
                <div className={styles.number}>100</div>
              </div>
            )}
          </div>
        </div>
        <div className={styles.bottom}></div>
      </div>
    </>
  );
};

export default Answer;
