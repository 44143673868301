import { store } from "../../../config/store";
import { died } from "../../Dialogs/reducer";
import { bloodPlayer } from "../../Spells/action/spellSets";
import { damageToPlayer } from "../../Stats/reducer";

export default function attackPlayer(monsterDamage) {
  const stats = store.getState().stats;

  store.dispatch(damageToPlayer(monsterDamage));

  bloodPlayer();

  // check if player died
  if (stats.hp - monsterDamage <= 0) {
    store.dispatch(died(true));
  }
}
