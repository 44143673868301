import React from "react";
import Header from "../Header";
import useTranslation from "../../utils/useTranslation";

const Roadmap = () => {
  const [__trans] = useTranslation();

  return (
    <Header title="Roadmap">
      <div className="table">
        <div className="table_column table_complete">
          <div className="value">15.08.2024</div>
          <div className="value_text">
            {__trans(
              "The launch of the Magic Ranc game at the 'early access' stage."
            )}
          </div>
        </div>

        <div className="table_column table_default">
          <div className="value">01.09.2024 - 15.09.2024</div>
          <div className="value_text">
            {__trans(
              "The 1st stage of the presale. 0.001 TON - 1 Magic Gem (Magic Gem Token)"
            )}
          </div>
        </div>

        <div className="table_column table_default">
          <div className="value">01.09.2024</div>
          <div className="value_text">
            {__trans(
              "The launch of the Magic Ranc game at the 'release' stage."
            )}
          </div>
        </div>

        <div className="table_column table_default">
          <div className="value">20.09.2024</div>
          <div className="value_text">
            {__trans(
              "Launching an advertising platform. Payment for advertising services is carried out using MGT."
            )}
          </div>
        </div>

        <div className="table_column table_default">
          <div className="value">01.10.2024 - 15.10.2024</div>
          <div className="value_text">
            {__trans(
              "The 2nd stage of the presale. 0.01 TON - 1 Magic Gem (Magic Gem Token)"
            )}
          </div>
        </div>

        <div className="table_column table_default">
          <div className="value">01.11.2024 </div>
          <div className="value_text">
            {__trans("Adding inventory and item functions to the game.")}
          </div>
        </div>

        <div className="table_column table_default">
          <div className="value">05.11.2024 </div>
          <div className="value_text">
            {__trans("Launching an auction of unique items.")}
          </div>
        </div>

        <div className="table_column table_default">
          <div className="value">10.11.2024 </div>
          <div className="value_text">
            {__trans("Adding a new battle mode between players.")}
          </div>
        </div>

        <div className="table_column table_default">
          <div className="value">Q4</div>
          <div className="value_text">{__trans("ARIDROP")}</div>
        </div>

        <div className="table_column table_default">
          <div className="value">15.12.2024 - 25.12.2024</div>
          <div className="value_text">
            {__trans(
              "The 3rd stage of the presale. 0.1 TON - 1 magic gem (Magic Gem Token)"
            )}
          </div>
        </div>

        <div className="table_column table_default">
          <div className="value">01.01.2025 </div>
          <div className="value_text">
            {__trans(
              "The launch of the blockchain. Transfer of accumulated MGT to users' personal wallets. Launching a crypto exchange."
            )}
          </div>
        </div>

        <div className="table_column table_default">
          <div className="value">15.02.2025 </div>
          <div className="value_text">
            {__trans("Updating the game engine")}
          </div>
        </div>

        <div className="table_column table_default">
          <div className="value_text">Coming soon</div>
        </div>
      </div>
    </Header>
  );
};

export default Roadmap;
