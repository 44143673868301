import agroBee from "./sound/beeagro.wav";
import attackBee from "./sound/beeattack.wav";
import deathBee from "./sound/beedeath.wav";
import stylesViking from "./Skeleton.module.scss";
import spriteBee from "./sprite/skeleton.png";
import avatar from "./sprite/avatar.png";

const Viking = {
  hp: 250,
  maxHp: 250,
  damage: 40,
  exp: 100,
  coin: 200,
  type: "skeleton",
  styles: stylesViking,
  agroSound: agroBee,
  attackSound: attackBee,
  deathSound: deathBee,
  sprite: spriteBee,
  spriteTypes: ["attack"],
  avatar,
};

export default Viking;
