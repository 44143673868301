import React from "react";
import styles from "./Home.module.scss";
import Level from "../../features/Stats/Level";
import Token from "../../features/Stats/Token";
import { useDispatch, useSelector } from "react-redux";

import loadMonsters from "../../features/Monsters/actions/loadMonsters";
import loadCharacters from "../../features/Characters/actions/loadCharacters";
import { setPreloadMap } from "../../features/Preload/reducer";
import Button from "../../components/button";
import { changePage } from "../../config/slices/menu";
import useTranslation from "../../utils/useTranslation";
import Icon from "../../components/icon";
import { SERVER_URL } from "../../config/constants";
import axios from "axios";
import Coin from "../../features/Stats/Coin";
const Home = () => {
  const dispatch = useDispatch();

  const stats = useSelector((state) => state.stats);

  const [__trans] = useTranslation();

  const [presale, setPresale] = React.useState(false);

  const shop = () => {
    dispatch(changePage("shop"));
  };

  const guide = () => {
    dispatch(changePage("guide"));
  };

  const partner = () => {
    dispatch(changePage("partner"));
  };

  const rating = () => {
    dispatch(changePage("rating"));
  };

  const bonuses = () => {
    dispatch(changePage("bonuses"));
  };

  const settings = () => {
    dispatch(changePage("settings"));
  };

  const heroes = () => {
    dispatch(changePage("heroes"));
  };

  const roadmap = () => {
    dispatch(changePage("roadmap"));
  };

  const play = () => {
    loadMonsters(); // загрузка монстров
    loadCharacters(); // загрузка персонажей
    dispatch(changePage(false));
    dispatch(setPreloadMap());
  };

  return (
    <div className={styles.bg}>
      <div className={styles.inner}>
        <div className={styles.top}>
          <div className={styles.top_left}>
            <div className={styles.username}>{stats.name}</div>
            <Level />
          </div>
          <div className={styles.top_right}>
            <Coin />
            <Token />
            <div onClick={settings} className={styles.settings}></div>
          </div>
        </div>

        <div className={styles.bottom}>
          <div className={styles.bottom_left}>
            <Button onClick={heroes} style={{ width: "35px" }} color={"blue"}>
              <div className={styles.button_inner}>
                <Icon i={"hero"} size={48} />

                <div className={styles.text}>{__trans("Heroes")}</div>
              </div>
            </Button>

            <Button onClick={roadmap} style={{ width: "35px" }} color={"blue"}>
              <div className={styles.button_inner}>
                <Icon i={"mission"} size={48} />
                <div className={styles.text}>{__trans("Roadmap")}</div>
              </div>
            </Button>

            {presale && (
              <Button onClick={shop} style={{ width: "35px" }} color={"blue"}>
                <div className={styles.button_inner}>
                  <Icon i={"gem"} size={48} />
                  <div className={styles.text}>{__trans("Presale")}</div>
                </div>
              </Button>
            )}
          </div>
          <div className={styles.bottom_right}>
            <div onClick={() => play()} className={styles.play}>
              {__trans("Play!")}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.mid}>
        <div className={styles.mid_left}>
          <div onClick={() => bonuses()} className={styles.test2}>
            <Icon i={"chest_special"} size={32} />
            {__trans("Bonuses")}
          </div>
          <div onClick={() => guide()} className={styles.test2}>
            <Icon i={"book_open"} size={32} />
            {__trans("Help")}
          </div>
          <div onClick={() => rating()} className={styles.test2}>
            <Icon i={"ranking"} size={32} />
            {__trans("Rating")}
          </div>
        </div>

        <div className={styles.mid_right}>
          <div onClick={() => partner()} className={styles.test}>
            <div className={styles.in}>
              <Icon i={"megaphone"} size={32} />
              <div className={styles.val}>{__trans("Affiliate Program")}</div>
            </div>
          </div>

          <a href="https://t.me/magicrankru" className={styles.test}>
            <div className={styles.in}>
              <div className={styles.reward}>
                +25000 <Icon i={"coin"} size={32} />
              </div>
              <div className={styles.val}>
                {__trans("Подпишитесь на канал (EN)")}
              </div>
            </div>
          </a>

          <a href="https://t.me/magicrankofficial" className={styles.test}>
            <div className={styles.in}>
              <div className={styles.reward}>
                +25000 <Icon i={"coin"} size={32} />
              </div>
              <div className={styles.val}>
                {__trans("Подпишитесь на канал (RU)")}
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Home;
