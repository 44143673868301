import React from "react";
import { useSelector } from "react-redux";

function useTranslation() {
  const settings = useSelector((state) => state.settings);

  const locale = settings.language;

  const [translations, setTranslations] = React.useState();

  React.useEffect(() => {
    if (locale == "en") {
      return;
    }

    (async function loadLangTranslations() {
      setTranslations((await import(`../utils/lang/${locale}.json`)).default);
    })();
  }, [locale]);

  const __trans = (key) => {
    if (locale == "en") {
      return key;
    }
    if (translations && translations[key]) {
      return translations[key];
    }
    return key;
  };

  return [__trans];
}

export default useTranslation;
