import Bee from "./bee";
import Viking from "./viking";
import Bat from "./bat";
import Skeleton from "./skeleton";
import Boar from "./boar";

const monsters = {
  bee: Bee,
  boar: Boar,
  viking: Viking,
  bat: Bat,
  skeleton: Skeleton,
};

export default monsters;
