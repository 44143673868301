import agroBee from "./sound/beeagro.wav";
import attackBee from "./sound/beeattack.wav";
import deathBee from "./sound/beedeath.wav";
import stylesViking from "./Boar.module.scss";
import spriteBee from "./sprite/boar.png";
import avatar from "./sprite/avatar.png";

const Viking = {
  hp: 90,
  maxHp: 90,
  damage: 10,
  exp: 20,
  coin: 75,
  type: "boar",
  styles: stylesViking,
  agroSound: agroBee,
  attackSound: attackBee,
  deathSound: deathBee,
  sprite: spriteBee,
  spriteTypes: [],
  avatar,
};

export default Viking;
