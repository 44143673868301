import React from "react";
import styles from "./Popup.module.scss";
import Button from "../button";

const Popup = ({ title, text, onClick, buttonName }) => {
  function handleClick() {
    if (typeof onClick === "function") {
      onClick();
    }
  }

  return (
    <div className={styles.popup}>
      <div className={styles.frame}>
        <div className={styles.title}>{title}</div>
        <div className={styles.text}>{text}</div>
        <Button onClick={handleClick} color={"gray"}>
          {buttonName || "OK"}
        </Button>
      </div>
    </div>
  );
};

export default Popup;
