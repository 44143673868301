import React from "react";
import styles from "./Stats.module.scss";
import { useDispatch } from "react-redux";
import Level from "./Level";
import Health from "./Health";
import Coin from "./Coin";
import Token from "./Token";
import Monsters from "./Monsters";
import { changePage } from "../../config/slices/menu";

const Bar = () => {
  const dispatch = useDispatch();

  const menu = () => {
    dispatch(changePage("home"));
  };

  return (
    <div className={styles.bar}>
      <div className={styles.left}>
        <Health />
        <Level />
        <Monsters />
      </div>
      <div className={styles.right}>
        <div onClick={menu} className={styles.home}></div>
        <Token />
        <Coin />
      </div>
    </div>
  );
};

export default Bar;
