import { motion, useAnimate } from "framer-motion";
import React from "react";
import styles from "./Label.module.scss";

const Label = ({ children }) => {
  const [scope, animate] = useAnimate();

  const labelRef = React.useRef();

  React.useEffect(() => {
    const enterAnimation = async () => {
      await animate(labelRef.current, { opacity: 1 }, { duration: 1 });
      await animate(
        labelRef.current,
        { opacity: 0 },
        { duration: 1, delay: 1 }
      );
    };
    enterAnimation();
  }, []);

  return (
    <motion.div
      ref={labelRef}
      initial={{ opacity: 0 }}
      className={styles.label}
    >
      <div className={styles.title}>{children}</div>
    </motion.div>
  );
};

export default Label;
