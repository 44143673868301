import React from "react";
import useTranslation from "../../../utils/useTranslation";
import Icon from "../../../components/icon";

const Inviting = () => {
  const [__trans] = useTranslation();

  return (
    <>
      <div className="value_md">
        <Icon i={"chest_special"} size={48} />
      </div>
      <div className="value_lg value_column">
        <div className="value_title">{__trans("Inviting friends")}</div>
      </div>
    </>
  );
};

export default Inviting;
