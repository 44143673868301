import React from "react";
import styles from "./Language.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { changeLanguage } from "../../config/slices/settings";
import { changePopup } from "../../config/slices/menu";

const Language = () => {
  const dispatch = useDispatch();

  const settings = useSelector((state) => state.settings);

  const closePopup = (lang) => {
    dispatch(changeLanguage(lang));

    dispatch(changePopup(false));
  };

  return (
    <div className={styles.popup}>
      <div className={styles.frame}>
        <div
          onClick={() => closePopup("ru")}
          className={`${styles.icon} ${styles.ru}`}
        >
          {settings.language == "ru" && (
            <>
              <div className={styles.check}></div>
            </>
          )}
        </div>
        <div
          onClick={() => closePopup("en")}
          className={`${styles.icon} ${styles.en}`}
        >
          {settings.language == "en" && (
            <>
              <div className={styles.check}></div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Language;
