import React from "react";
import styles from "./Progress.module.scss";

const Progress = ({ width }) => {
  const progressRef = React.useRef();

  const setProgress = (width) => {
    if (progressRef.current) {
      progressRef.current.style.background =
        "linear-gradient(to right, #fab037 0%, #fab037 " +
        width +
        "%, #000 " +
        width +
        "%, #000 100%)";
    }
  };

  React.useEffect(() => {
    setProgress(width);
  }, [width]);

  return (
    <div ref={progressRef} className={styles.body}>
      <div className={styles.info}></div>
    </div>
  );
};

export default Progress;
