import React from "react";
import styles from "./Message.module.scss";
import { useDispatch } from "react-redux";
import { died } from "../reducer";
import Button from "../../../components/button";
import rebithPlayer from "../../Player/actions/rebithPlayer";
import useTranslation from "../../../utils/useTranslation";

const Dialogs = () => {
  const [__trans] = useTranslation();

  const dispatch = useDispatch();

  const rebirth = () => {
    rebithPlayer();
    dispatch(died(false));
  };

  return (
    <>
      <div className={styles.cover}></div>
      <div style={{ width: "100%" }} className={styles.message}>
        <div className={styles.dead}>{__trans("You are dead")}</div>

        <div className={styles.manage}>
          <Button onClick={() => rebirth()} color={"blue"}>
            {__trans("To be reborn in the capital of Viula")}
          </Button>
        </div>
      </div>
    </>
  );
};

export default Dialogs;
