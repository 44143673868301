import React from "react";
import styles from "./Seller.module.scss";

import Input from "../../../components/input";
import Button from "../../../components/button";
import buyToken from "../actions/buyToken";
import CharacterFrame from "../character-frame";
import characterData from "../../../data/characters";
import useTranslation from "../../../utils/useTranslation";
import { closeDialog } from "../actions/dialog-manager";

const Seller = () => {
  const [__trans] = useTranslation();

  const rate = 10000; // 1 к 100

  const [gold, setGold] = React.useState(0);

  const [token, setToken] = React.useState(0);

  const buy = () => {
    if (token) {
      buyToken(token);
    }
  };
  const close = () => {
    closeDialog();
  };
  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name == "token") {
      if (isNaN(value)) {
        return false;
      }

      setToken(value);
      setGold(value * rate);
    }
  };

  const data = characterData["seller"];

  return (
    <>
      <CharacterFrame avatar={data.avatar} title={data.name} />
      <div className={styles.b}>
        <div className={styles.form}>
          <div className={styles.price}>
            <div className={styles.hint}>{__trans("You give")}</div>
            <div className={styles.gold}></div>
            {gold}
          </div>
          <div className={styles.price}>
            <div className={styles.hint}>{__trans("You get")}</div>

            <div className={styles.token}></div>
            <Input
              style={{ width: "50px" }}
              type="text"
              name="token"
              value={token}
              onChange={handleChange}
            />
          </div>
          <div className={styles.buttons}>
            <Button style={{ marginTop: "10px" }} block={true} onClick={buy}>
              {__trans("Buy")}
            </Button>
            <Button onClick={() => close()} color={"red"} block={true}>
              {__trans("Bye")}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Seller;
