import { store } from "../../../config/store";
import { errorMessage } from "../../Messages/actions/message-manager";
import { healPlayerSpell } from "../../Spells/action/spellSets";
import { healHp, loseCoin } from "../../Stats/reducer";
import { closeDialog } from "./dialog-manager";

const buyHealing = () => {
  const stats = store.getState().stats;

  if (isNaN(stats.coin)) {
    return false;
  }

  if (stats.coin < 100) {
    errorMessage("Not enough gold!");

    return false;
  }

  store.dispatch(loseCoin(100));

  store.dispatch(healHp());

  closeDialog();

  healPlayerSpell();
};

export default buyHealing;
