import React from "react";
import styles from "./Healer.module.scss";
import Text from "../../../components/text";
import characterData from "../../../data/characters";
import CharacterFrame from "../character-frame";
import { closeDialog } from "../actions/dialog-manager";
import Answer from "../../../components/answer";
import useTranslation from "../../../utils/useTranslation";
import Button from "../../../components/button";

const Knight = () => {
  const [__trans] = useTranslation();

  const [answer, setAnswer] = React.useState(false);

  const close = () => {
    closeDialog();
  };

  const data = characterData["knight"];

  return (
    <>
      <CharacterFrame avatar={data.avatar} title={data.name} />
      <div className={styles.b}>
        <div>
          {answer == false && <Text text={__trans("How can I help you?")} />}

          {answer == 1 && (
            <Text
              text={__trans(
                "To earn coins: kill monsters. There are a lot of strong monsters in the sinister forest in the east, you should go there prepared."
              )}
            />
          )}

          {answer == 2 && <Text text={__trans("Head southwest")} />}

          {answer == 3 && (
            <Text
              text={__trans(
                "Monsters are reborn after killing all the monsters on the map"
              )}
            />
          )}
        </div>
        <div className={styles.buttons}>
          <Answer onClick={() => setAnswer(1)}>
            {__trans("How do I earn coins?")}
          </Answer>

          <Answer onClick={() => setAnswer(2)}>
            {__trans("Where can I buy magic gems?")}
          </Answer>

          <Answer onClick={() => setAnswer(3)}>
            {__trans("How often do monsters reborn on the map?")}
          </Answer>

          <Button onClick={() => close()} color={"red"} block={true}>
            {__trans("Bye")}
          </Button>
          {/* <div className={styles.info}>
            <div className={styles.top}></div>
            <div className={styles.body}>
              <div className={styles.text}>
                Купить
                <div className={styles.price}>
                  <div className={styles.icon}></div>
                  <div className={styles.number}>100</div>
                </div>
              </div>
            </div>
            <div className={styles.bottom}></div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Knight;
