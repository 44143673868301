import React from "react";
import Icon from "../../../components/icon";

const Number = ({ num }) => {
  if (num == 1) {
    return <Icon i={"medal_gold"} size={32} />;
  }

  if (num == 2) {
    return <Icon i={"medal_silver"} size={32} />;
  }

  if (num == 3) {
    return <Icon i={"medal_bronze"} size={32} />;
  }

  return num;
};

export default Number;
