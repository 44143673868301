const characters = [
  {
    type: "healer",
    position: [12, 39],
  },
  {
    type: "seller",
    position: [16, 48],
  },

  {
    type: "knight",
    position: [26, 35],
  },
];

export default characters;
