import React from "react";
import { useSelector } from "react-redux";
import Home from "../../pages/Home";
import Settings from "../../pages/Settings";
import Rating from "../../pages/Rating";
import Partner from "../../pages/Partner";
import Shop from "../../pages/Shop";
import "./settings.scss";
import Heroes from "../../pages/Heroes";
import Bonuses from "../../pages/Bonuses";
import Guide from "../../pages/Guide";
import Roadmap from "../../pages/Roadmap";

const Menu = () => {
  const menu = useSelector((state) => state.menu);

  if (menu.page == "guide") {
    return <Guide />;
  }

  if (menu.page == "bonuses") {
    return <Bonuses />;
  }

  if (menu.page == "heroes") {
    return <Heroes />;
  }

  if (menu.page == "shop") {
    return <Shop />;
  }

  if (menu.page == "partner") {
    return <Partner />;
  }

  if (menu.page == "settings") {
    return <Settings />;
  }

  if (menu.page == "rating") {
    return <Rating />;
  }

  if (menu.page == "roadmap") {
    return <Roadmap />;
  }

  if (menu.page == "home") {
    return <Home />;
  }
};

export default Menu;
