import React from "react";
import styles from "./Input.module.scss";

const Input = ({ type, value, name, placeholder, onChange, style }) => {
  return (
    <div className={`${styles.textfield} ${styles.md}`}>
      <input
        style={style}
        autoComplete="off"
        type={type}
        value={value}
        name={name}
        placeholder={placeholder}
        onChange={onChange}
      />
    </div>
  );
};

export default Input;
