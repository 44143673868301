import React from "react";
import { useSelector } from "react-redux";
import playerData from "../../data/player";
import useSound from "use-sound";

const Player = () => {
  const settings = useSelector((state) => state.settings);

  const data = playerData["assasin"];

  const [walkPlay, { stop: walkStop }] = useSound(data.walkSound, {
    volume: settings.sound / 100,
    loop: true,
  });

  const [swordPlay] = useSound(data.attackSound, {
    volume: settings.sound / 100,
  });

  const [sprite, setSprite] = React.useState("down");

  const player = useSelector((state) => state.player);

  React.useEffect(() => {
    if (player.playerAttacked == true) {
      swordPlay();
    }

    if (player.direction == "up") {
      if (player.playerMoved == true) {
        setSprite("up_run");
      } else if (player.playerAttacked == true) {
        setSprite("up_attack");
      } else {
        setSprite("up");
      }
    }

    if (player.direction == "down") {
      if (player.playerMoved == true) {
        setSprite("down_run");
      } else if (player.playerAttacked == true) {
        setSprite("down_attack");
      } else {
        setSprite("down");
      }
    }

    if (player.direction == "right") {
      if (player.playerMoved == true) {
        setSprite("right_run");
      } else if (player.playerAttacked == true) {
        setSprite("right_attack");
      } else {
        setSprite("right");
      }
    }

    if (player.direction == "left") {
      if (player.playerMoved == true) {
        setSprite("left_run");
      } else if (player.playerAttacked == true) {
        setSprite("left_attack");
      } else {
        setSprite("left");
      }
    }
  }, [player.direction, player.playerAttacked, player.playerMoved]);

  React.useEffect(() => {
    if (player.playerMoved == true) {
      walkPlay();
    } else {
      walkStop();
    }
    return () => {
      walkStop();
    };
  }, [player.playerMoved]);

  return (
    <div
      style={{
        width: "60px",
        height: "60px",
        position: "absolute",
        //border: "1px solid red",
        overflow: "hidden",
        left: player.px[0],
        top: player.px[1],
      }}
      className={data.styles.assasin}
    >
      <div
        style={{
          backgroundImage: `url(${data.sprite})`,
        }}
        key={player.playerAttacked && 1}
        facing={sprite}
        className={`${data.styles.spritesheet}`}
      ></div>
    </div>
  );
};

export default Player;
