import React from "react";
import { motion } from "framer-motion";

const Text = ({ text }) => {
  const splitText = text.split(" ");

  return (
    <>
      {splitText.map((el, i) => (
        <motion.span
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            duration: 1,
            delay: i / 10,
          }}
          key={i}
        >
          {el}{" "}
        </motion.span>
      ))}
    </>
  );
};

export default Text;
