import { store } from "../../../config/store";
import uuidv4 from "../../../utils/uuid-v4";
import { addHint } from "../reducer";

const createHint = (position, value, type, lifetime = 1) => {
  store.dispatch(
    addHint({
      id: uuidv4(),
      position: position,
      value: value,
      type: type,
      lifetime: lifetime,
    })
  );
};

export default createHint;
