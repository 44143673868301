import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Preload from "./features/Preload";
import Intro from "./features/Intro";
import Main from "./features/Main";
import World from "./features/World";
import mainMusicFile from "./data/sounds/main.mp3";
import mapMusicFile from "./data/maps/1/assets/music_castle.mp3";
import useSound from "use-sound";
import Continue from "./features/Continue";
import Viewport from "./features/Viewport";
import Shut from "./features/Shut";
import { changeLanguage } from "./config/slices/settings";
import { useTelegram } from "./utils/useTelegram";

function Start() {
  const dispatch = useDispatch();

  const { user } = useTelegram();

  const { status, data } = useSelector((state) => state.stats);

  const settings = useSelector((state) => state.settings);

  const menu = useSelector((state) => state.menu);

  const preload = useSelector((state) => state.preload);

  const [wait, setWait] = React.useState(true);

  const AudioContextRef = React.useRef(false);

  const [IsMainMusicPlaying, setIsMainMusicPlaying] = React.useState(false);

  const [IsMapMusicPlaying, setIsMapMusicPlaying] = React.useState(false);

  const [mainMusic, { stop: mainMusicStop, sound: mainSound }] = useSound(
    mainMusicFile,
    {
      volume: settings.music / 100,
      loop: true,
      onplay: () => setIsMainMusicPlaying(true),
      onstop: () => setIsMainMusicPlaying(false),
    }
  );

  const [mapMusic, { stop: mapMusicStop, sound: mapSound }] = useSound(
    mapMusicFile,
    {
      volume: settings.music / 100,
      loop: true,
      onplay: () => setIsMapMusicPlaying(true),
      onstop: () => setIsMapMusicPlaying(false),
    }
  );

  React.useEffect(() => {
    if (settings.firstLaunch == true && data) {
      if (data.language == "ru") {
        dispatch(changeLanguage("ru"));
      }
    }
  }, [settings.firstLaunch, data]);

  React.useEffect(() => {
    if (AudioContextRef?.current?.state == "running") {
      if (menu.page) {
        mapMusicStop();
        if (IsMainMusicPlaying == false) {
          mainMusic();
        }
      } else {
        mainMusicStop();

        if (IsMapMusicPlaying == false) {
          mapMusic();
        }
      }
    }
  }, [menu.page, AudioContextRef.current?.state]);

  React.useEffect(() => {
    if (mainSound) {
      mainSound.volume(settings.music / 100);
    }
  }, [settings.music]);

  const Cont = () => {
    AudioContextRef.current = new AudioContext();
    setWait(false);
  };

  // если сервер выключен
  // if (menu.serverOff == true) {
  //   return (
  //     <Viewport>
  //       <Shut />
  //     </Viewport>
  //   );
  // }

  // предзагрузка
  if (preload.menu || preload.map) {
    return (
      <Viewport>
        <Preload />
      </Viewport>
    );
  }

  // история
  if (settings.firstLaunch == true) {
    return (
      <Viewport>
        <Intro />
      </Viewport>
    );
  }

  if (wait) {
    return (
      <Viewport>
        <Continue onClick={() => Cont()} />
      </Viewport>
    );
  }

  // меню
  if (menu.page) {
    return (
      <Viewport>
        <Main />
      </Viewport>
    );
  }

  return (
    <>
      <Viewport>
        <World />
      </Viewport>
    </>
  );
}

export default Start;
