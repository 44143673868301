import { changePaused } from "../../../config/slices/menu";
import { store } from "../../../config/store";
import changeMap from "../../Map/actions/changeMap";
import { healHp } from "../../Stats/reducer";

const rebithPlayer = () => {
  const startPosition = [25, 37];

  changeMap(1, startPosition);

  store.dispatch(healHp());
  store.dispatch(changePaused(false));
  //store.dispatch(changePosition([25, 37]));
  //store.dispatch(changePixelPosition([25 * 60, 37 * 60]));
};

export default rebithPlayer;
