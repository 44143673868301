import React from "react";
import useWindowSize from "../Map/actions/useWindowSize";
import { useDispatch } from "react-redux";
import { changeHeight } from "./reducer";

const Viewport = ({ children }) => {
  const { width, height } = useWindowSize();

  const dispatch = useDispatch();

  const viewportRef = React.useRef(false);

  React.useEffect(() => {
    const div = viewportRef.current;

    if (height > width) {
      dispatch(changeHeight(width));
      div.className = "reverse";
    } else {
      dispatch(changeHeight(height));

      div.className = "app";
    }
  }, [width, height]);

  return <div ref={viewportRef}>{children}</div>;
};

export default Viewport;
