import React from "react";
import Monster from "./Monster";
import { useSelector } from "react-redux";

const Monsters = () => {
  const monsters = useSelector((state) => state.monsters);

  const [monstersToRender, setMonstersToRender] = React.useState(null);

  const currentMap = useSelector((state) => state.player.map);

  React.useEffect(() => {
    const monsterArray = [];

    if (monsters.components) {
      if (JSON.stringify(monsters.components) === JSON.stringify({})) {
        setMonstersToRender(null);
      } else if (monsters.components[currentMap]) {
        Object.keys(monsters.components[currentMap]).forEach((uuid) => {
          monsterArray.push(
            <Monster
              key={uuid}
              monster={monsters.components[currentMap][uuid]}
            />
          );
        });

        setMonstersToRender(monsterArray);
      }
    }
  }, [monsters]);

  return monstersToRender;
};

export default Monsters;
