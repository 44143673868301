import map1 from "./1";
import map2 from "./2";
import map3 from "./3";
const maps = {
  1: map1,
  2: map2,
  3: map3,
};

export default maps;
