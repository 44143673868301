import React from "react";
import axios from "axios";
import styles from "./Shop.module.scss";

import Input from "../../components/input";
import Header from "../Header";
import Button from "../../components/button";
import Popup from "../../components/popup";
import useTranslation from "../../utils/useTranslation";
import { useSelector } from "react-redux";
import { SERVER_URL } from "../../config/constants";

const Shop = () => {
  const [__trans] = useTranslation();

  const id = useSelector((state) => state.stats._id);

  const [amount, setAmount] = React.useState(50);

  const ton = { id: "ton", price: 0.01, name: "TON", short: "TON" };

  const usd = {
    id: "tron",
    price: 0.07,
    name: "USDT (TRC-20)",
    short: "USDT",
  };

  const [method, setMethod] = React.useState(ton);

  const [popup, setOpen] = React.useState(false);

  // выставляем платеж в бота
  const getPayment = async (token) => {
    const response = await axios.get(
      `${SERVER_URL}/payment/get?id=${id}&type=${method.id}&token=${token}`
    );

    setOpen({
      title: __trans("The payment has been created"),
      text: __trans("Payment details have been sent to our Telegram Bot"),
    });
  };

  const getPrice = (value) => {
    const result = value * method.price;

    let price;

    if (result > 1) {
      price = result.toFixed(0);
    }
    price = result.toFixed(1);

    return price + " " + method.short;
  };

  const handleChange = (e) => {
    if (isNaN(e.target.value)) {
      return false;
    }
    setAmount(e.target.value);
  };

  return (
    <Header title="Presale">
      {popup && (
        <Popup
          title={popup.title}
          text={popup.text}
          onClick={() => setOpen(false)}
        />
      )}

      <ul className="tabs tabs-center">
        <li
          onClick={() => setMethod(ton)}
          className={method.id == ton.id && "active"}
        >
          {ton.name}
        </li>
        <li
          onClick={() => setMethod(usd)}
          className={method.id == usd.id && "active"}
        >
          {usd.name}
        </li>
      </ul>

      <div className={` ${styles.goods}`}>
        <div className={styles.good}>
          <div className={styles.icon}></div>
          <Input
            onChange={(e) => handleChange(e)}
            value={amount}
            name="amount"
          />
          <Button onClick={() => getPayment(amount)} color={"blue"}>
            Купить {getPrice(amount)}
          </Button>
        </div>

        <div className={styles.good}>
          <div className={styles.icon1}></div>
          <div className={styles.title}>10</div>
          <Button onClick={() => getPayment(10)} color={"blue"}>
            Купить {getPrice(10)}
          </Button>
        </div>

        <div className={styles.good}>
          <div className={styles.icon2}></div>
          <div className={styles.title}>100</div>
          <Button onClick={() => getPayment(100)} color={"blue"}>
            Купить {getPrice(100)}
          </Button>
        </div>

        <div className={styles.good}>
          <div className={styles.icon3}></div>
          <div className={styles.title}>1000</div>
          <Button onClick={() => getPayment(1000)} color={"blue"}>
            Купить {getPrice(1000)}
          </Button>
        </div>

        <div className={styles.good}>
          <div className={styles.icon4}></div>
          <div className={styles.title}>10000</div>
          <Button onClick={() => getPayment(10000)} color={"blue"}>
            Купить {getPrice(10000)}
          </Button>
        </div>

        <div className={styles.good}>
          <div className={styles.icon5}></div>
          <div className={styles.title}>100000</div>
          <Button onClick={() => getPayment(100000)} color={"blue"}>
            Купить {getPrice(100000)}
          </Button>
        </div>
      </div>
    </Header>
  );
};

export default Shop;
