import React from "react";
import { useSelector } from "react-redux";
import Message from "./message";

const Messages = () => {
  const data = useSelector((state) => state.messages.data);

  return (
    <>
      {data.map(function (data) {
        return <Message data={data} />;
      })}
    </>
  );
};

export default Messages;
