import React from "react";
import styles from "./Spells.module.scss";
import { useDispatch } from "react-redux";
import { deleteSpell } from "./reducer";

const Spell = ({ data }) => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (data.lifetime) {
      setTimeout(() => {
        dispatch(deleteSpell(data.id));
      }, data.lifetime * 1000);
    }
  }, []);

  let className = "";

  switch (data.type) {
    case "blood":
      className = styles.blood;
      break;
    case "claw":
      className = styles.claw;
      break;
    case "heal":
      className = styles.heal;
      break;
  }

  return (
    <>
      <div
        style={{
          position: "absolute",
          top: data.position.y * 60,
          left: data.position.x * 60,
        }}
        className={className}
      >
        <div className={styles.spritesheet}></div>
      </div>
    </>
  );
};

export default Spell;
