import React from "react";
import styles from "./Reward.module.scss";
import Icon from "../../../../components/icon";

const Reward = ({ reward, type }) => {
  return (
    <div className={styles.frame}>
      {type == "gold" && <Icon i={"coin"} size={32} />}
      {type == "token" && <Icon i={"jewel2_blue"} size={32} />}
      {reward}
    </div>
  );
};

export default Reward;
