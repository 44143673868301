export const loadImage = (image) => {
  return new Promise((resolve, reject) => {
    const loadFile = new Image();

    loadFile.src = image;

    loadFile.onload = () =>
      //setTimeout(() => {
      resolve(image);
    // }, 2000);

    loadFile.onerror = (err) => reject(err);
  });
};

export const promiseAll = (objects, completeFunc) => {
  Promise.all(objects.map((image) => loadImage(image)))
    .then(() => {
      completeFunc();
    })
    .catch((err) => console.log("Failed to load images", err));
};
