import React from "react";
import useSound from "use-sound";
import Health from "../Health";

const Monster = ({ monster }) => {
  const [deathPlay] = useSound(monster.deathSound);

  const [agroPlay] = useSound(monster.agroSound);

  const [attackPlay] = useSound(monster.attackSound);

  const size = 60;

  const [sprite, setSprite] = React.useState("left");

  React.useEffect(() => {
    //if (monster.aggravation == true) beeAgroPlay();
  }, [monster.aggravation]);

  React.useEffect(() => {
    if (monster.died == true) {
      deathPlay();
    }
  }, [monster.died]);

  // меняем спрайт
  React.useEffect(() => {
    if (monster.direction == "up") {
      if (monster.attack == true) {
        setSprite("up_attack");
      } else if (monster.died == true) {
        setSprite("up_dead");
      } else {
        setSprite("up");
      }
    }
    if (monster.direction == "down") {
      if (monster.attack == true) {
        setSprite("down_attack");
      } else if (monster.died == true) {
        setSprite("down_dead");
      } else {
        setSprite("down");
      }
    }
    if (monster.direction == "left") {
      if (monster.died == true) {
        setSprite("left_dead");
      } else if (monster.attack == true) {
        setSprite("left_attack");
      } else {
        setSprite("left");
      }
    }
    if (monster.direction == "right") {
      if (monster.attack == true) {
        setSprite("right_attack");
      } else if (monster.died == true) {
        setSprite("right_dead");
      } else {
        setSprite("right");
      }
    }
  }, [monster.direction, monster.attack, monster.died]);

  return (
    <div
      style={{
        width: "60px",
        height: "60px",
        position: "absolute",
        //border: "1px solid red",
        overflow: "hidden",
        left: monster.position[0] * size,
        top: monster.position[1] * size,

        transition:
          "left .35s ease-in-out .15s, top .35s ease-in-out .15s, opacity .35s ease-in-out",
      }}
    >
      <Health value={monster.hp} max={monster.maxHp} />
      <div>
        <div
          style={{
            backgroundImage: `url(${monster.sprite})`,
          }}
          facing={sprite}
          className={monster.styles.spritesheet}
        ></div>
      </div>
    </div>
  );
};

export default Monster;
