import React from "react";
import { useSelector } from "react-redux";
import PreloadMenu from "./PreloadMenu";
import PreloadMap from "./PreloadMap";

const Preload = () => {
  const preload = useSelector((state) => state.preload);

  if (preload.menu) {
    return <PreloadMenu />;
  }

  if (preload.map) {
    return <PreloadMap />;
  }
};

export default Preload;
