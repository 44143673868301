import React from "react";
import styles from "./Healer.module.scss";
import buyHealing from "../actions/buyHealing";
import Text from "../../../components/text";
import characterData from "../../../data/characters";
import CharacterFrame from "../character-frame";
import Answer from "../../../components/answer";
import useTranslation from "../../../utils/useTranslation";
import { closeDialog } from "../actions/dialog-manager";
import Button from "../../../components/button";

const Healer = () => {
  const [__trans] = useTranslation();

  const buy = () => {
    buyHealing();
  };

  const close = () => {
    closeDialog();
  };

  const data = characterData["healer"];

  return (
    <>
      <CharacterFrame avatar={data.avatar} title={data.name} />
      <div className={styles.b}>
        <div>
          <Text
            text={__trans("The potion instantly restores your hit points.")}
          />
        </div>
        <div className={styles.buttons}>
          <Answer price={100} onClick={() => buy()}>
            {__trans("Restore hit points.")}
          </Answer>
          <Button onClick={() => close()} color={"red"} block={true}>
            {__trans("Bye")}
          </Button>
        </div>
      </div>
    </>
  );
};

export default Healer;
